import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { timeSince } from "../../common/helper/timeSince";
import { GroupFormLoader } from "../../common/loaders/GroupFormLoader";
import { useEventContext } from "../../contexts/EventContext";
import { OPEN_POPUP_EVENT_GET_ACCOUNT_DATA_ERROR } from "../custom-events/constants";
import { trigger } from "../custom-events/customEvents";
import { EventHeader } from "./EventHeader";
import { getEvents } from "../../API";

export function ListEvent({ data }) {
  const history = useHistory();
  const { saveLocalEventData } = useEventContext();
  const [eventsFiltered, setEventsFiltered] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstMount, setIsFirstMount] = useState(true);
  const { filterEventsSelected } = useEventContext();

  const selectMenuHandle = (event) => {
    saveLocalEventData(event);
    history.push(`/events/details/${event.id}`);
  };

  useEffect(() => {
    if (isFirstMount) {
      setIsFirstMount(false);
    } else {
      async function fetchData() {
        setIsLoading(true);

        try {
          const result = await getEvents(filterEventsSelected);

          setEventsFiltered(result);
        } catch (error) {
          trigger(OPEN_POPUP_EVENT_GET_ACCOUNT_DATA_ERROR);
        }

        setIsLoading(false);
      }

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEventsSelected]);

  return (
    <div>
      <EventHeader itemActive={filterEventsSelected} />
      {isLoading ? (
        <GroupFormLoader />
      ) : eventsFiltered && eventsFiltered.length > 0 ? (
        <ul className="text-white flex flex-col mt-10">
          {eventsFiltered.map((event) => {
            const timeAgo = Date.now() - event.createdAtTimestamp;
            return (
              <li
                key={event.id}
                className="mt-3"
                onClick={() => selectMenuHandle(event)}
              >
                <h3 className="flex justify-between items-center mx-6">
                  <span className="cursor-pointer text-white text-2xl uppercase font-normal">
                    {event.name}
                  </span>
                  <span className="text-gray-300">
                    <div className="flex items-center">
                      <img className="mr-1 h-3.5" src={"img/coin.svg"} alt="" />
                      {`${event.eventBalance || "0"}`}
                    </div>
                  </span>
                </h3>
                <div className="flex mt-3 ml-6">
                  <span
                    className={`inline-flex items-center justify-center px-2 py-1 font-bold leading-none  ${
                      event.published ? "text-violet" : "text-gray"
                    } rounded-full`}
                  >
                    {event.published ? "Published" : "Draft"}
                  </span>
                  <span className="ml-4 opacity-50">
                    Created {timeSince(new Date(Date.now() - timeAgo))} ago
                  </span>
                </div>
                <div className="line"> </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="px-6 mt-4 text-lg text-gray-400">No events found</div>
      )}
    </div>
  );
}
