import React, { useEffect, useState } from "react";
import { WrapperPopup } from "./WrapperPopup";
import {
  CLOSE_POPUP_EVENT,
  OPEN_POPUP_BACKEND_ERROR,
} from "../custom-events/constants";
import { defaultMessageError } from "../../common/helper/codeErrorFirebaseAuth";
import { off, on } from "../custom-events/customEvents";

export const BackendPopupError = () => {
  const openEventName = OPEN_POPUP_BACKEND_ERROR;
  const closeEventName = CLOSE_POPUP_EVENT;

  const [errorMsg, setErrorMsg] = useState(defaultMessageError);

  const onError = (e) => {
    setErrorMsg(e?.detail?.message || defaultMessageError);
  };

  const onCloseModal = () => {
    setErrorMsg(defaultMessageError);
  };

  useEffect(() => {
    on(openEventName, onError);

    return () => {
      off(openEventName, onError);
    };
  }, [openEventName]);

  useEffect(() => {
    on(closeEventName, onCloseModal);
    return () => {
      off(closeEventName, onCloseModal);
    };
  });

  return (
    <WrapperPopup openEventName={openEventName} isSuccess={false}>
      {errorMsg}
    </WrapperPopup>
  );
};
