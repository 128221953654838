import React, { useEffect, useState } from "react";
import { Title } from "../../common/titles/Title";
import { Link } from "react-router-dom";
import { getAttendees } from "../../API";

export function AttendeesDetails({ data }) {
  const [attendees, setAttendees] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAttendees(data.eventId);
      setAttendees(result);
    };
    fetchData();
  }, [data]);

  const renderAttendeesList = () => {
    return attendees.map((el, i) => (
      <Link to={`/events/attendee/${data.eventId}/${el.phone}`} key={i}>
        <div className="flex justify-between items-center text-sm	 py-5  border-b	">
          <div className="w-32">
            <div className="pb-1">{el.name}</div>
              <div className="pb-1">{el.email}</div>
            <div>{el.phone}</div>
          </div>
          <div className="flex-1 flex justify-center">{el.tickets}</div>
          <div className="w-20  flex justify-center">{el.total}</div>
          <div className="flex-1 flex justify-center">{`${el.scanned}/${el.tickets}`}</div>
        </div>
      </Link>
    ));
  };

  return (
    <div>
      <Title extraStyles="pl-0 pr-0 pb-3 flex justify-between items-center">
        <span className="text-white font-bold">Attendees</span>
      </Title>
      <div className="text-sm font-medium mb-5">
        Scanned {data.event?.scannedTickets} / {data.event?.soldTickets}
      </div>
      {/*<button*/}
      {/*  disabled={true}*/}
      {/*  className="text-violet text-sm font-bold rounded border border-violet px-2 py-1"*/}
      {/*>*/}
      {/*  Resend tickets*/}
      {/*</button>*/}
      <div className="flex justify-between items-center text-xs	 text-gray-500 mt-10 leading-4">
        <div className="w-32">Attendee</div>
        <div className="flex-1 flex justify-center">Ticket</div>
        <div className="w-20 flex justify-center">Total</div>
        <div className="flex-1 flex justify-center">In/Bought</div>
      </div>
      {renderAttendeesList()}
    </div>
  );
}
