const codeErrorFirebaseAuth = {
  "auth/invalid-email": "Invalid Email",
  "auth/invalid-email-verified": "Email Verified is invalid",
  "auth/invalid-display-name": "Full name must not be empty.",
  "auth/invalid-phone-number": "The phone number is invalid",
};

export const defaultMessageError =
  "Ooops ! Something went wrong. Please retry later.";

export const defaultMessageErrorNotVerifiedUser =
  "Wait for verification.";

export const defaultMessageErrorCodeMobile =
  "Sorry but this number is not registered";

export default codeErrorFirebaseAuth;
