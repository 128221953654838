import React from "react";
import PropTypes from "prop-types";

import { Logo } from "../logo/Logo";
import { Link } from "react-router-dom";
import { LANDING_URL } from "../../config/env";

export function Navbar({ children, pathHome = "/" }) {
  return (
    <header
      className={`flex flex-row py-10 items-center ${
        pathHome ? "padding-container padding-top-container" : ""
      }`}
    >
      <a href={LANDING_URL}>
        <img className="" src="/img/arrow_left.svg" alt="arrow" />
      </a>
      <Logo path={pathHome} />
      {children}
    </header>
  );
}

Navbar.propTypes = {
  pathHome: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
