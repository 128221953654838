import React, { useEffect, useState } from "react";
import { Container } from "../../common/layouts/Container";
import { HeaderDetails } from "../../common/header/HeaderDetails";
import { useParams } from "react-router-dom";
import { getAttendee } from "../../API";
import { format } from "date-fns";

export const Attendee = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const { eventId, phone } = params;

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAttendee(eventId, phone);
      setData(data);
    };
    fetchData();
  }, [eventId, phone]);

  const renderList = (list) => {
    return list.map((el, i) => (
      <div className="flex justify-between  text-sm	 py-5  border-b	" key={i}>
        <div className="w-28">
          <div>{el.name}</div>
          <div>{el.email}</div>
          <div>{el.phone}</div>
          <div
            style={{
              fontSize: 10,
              color: "#000000",
              opacity: 0.5,
            }}
          >
            <div className="text-white">{el.order?.number}</div>
            <div className="text-white">
              {el.order?.date
                ? format(new Date(el.order?.date), "yyyy-MM-dd")
                : ""}
            </div>
          </div>
        </div>
        <div className="w-28 text-center">
          <div>{el.ticketInfo?.name}</div>
          <div>Code {el.ticketInfo?.ticketNumber}</div>
          <div> {el.ticketInfo?.price}</div>
        </div>
        <div className="w-12 text-center">
          {Math.round(el.scanned / 2)}/{Math.floor(el.scanned / 2)}
        </div>
      </div>
    ));
  };

  const renderContent = () => {
    if (!data) return null;
    const { buyer, comment, attendee, eventId } = data;
    return (
      <Container withDecoration={true}>
        <HeaderDetails
          pathReturn={`/events/details/${eventId}?tab=3`}
          textTitle="Attendee Info"
        />
        <div className="w-full px-5 py-5 text-sm">
          <div className="mb-5">
            <div className="italic font-bold mb-1.5">Buyer</div>
            <div className="mb-1">{buyer?.name}</div>
            <div>{buyer?.email}</div>
            <div>{buyer?.phone}</div>
          </div>
          <div className="mb-6">
            <div className="italic font-bold mb-1.5">Buyer Comment</div>
            <div>{comment}</div>
          </div>
          {/*<div className="mb-10">*/}
          {/*  <button*/}
          {/*    disabled={true}*/}
          {/*    className="text-violet text-sm font-bold rounded border border-violet px-2 py-1"*/}
          {/*  >*/}
          {/*    Resend tickets*/}
          {/*  </button>*/}
          {/*</div>*/}
          <div className="flex justify-between  text-xs	 text-gray-500 mt-10 leading-4">
            <div className="w-28">Attendee</div>
            <div className="w-28 text-center">Ticket</div>
            <div className="w-12 text-center">In/Out</div>
          </div>
          {renderList(attendee)}
        </div>
      </Container>
    );
  };
  return renderContent();
};
