import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useHistory, useParams } from "react-router-dom";
import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";
import { createTicket } from "../../API";
import { useEventContext } from "../../contexts/EventContext";
import {current} from "@reduxjs/toolkit";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  currency: Yup.string().required("Currency name of event is required"),
  type: Yup.string().required("The type name of ticket is required"),
  quantity: Yup.number()
    .typeError("The quantity of tickets must be a number")
    .positive("The quantity of tickets must be greater than zero")
    .required("The quantity is required"),
  max: Yup.number()
    .typeError("The maximum number of tickets must be a number")
    .positive("The maximum number of tickets must be greater than zero")
    .required("The maximum number of tickets that can be purchased is required")
    .test(
      "is-greater",
      "The maximum number of tickets must equal the number of tickets available",
      function (value) {
        const { quantity } = this.parent;

        return quantity >= value;
      }
    ),
  price: Yup.number()
    .typeError("The price of tickets must be a number")
    .required("The price is required"),
  description: Yup.string()
    .required("The description is required")
    .max(2000, "Max number of characters - 2000."),
});

export function CreateTicketForm() {
  const { id } = useParams();
  const { currencies } = useEventContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      currency: "",
      type: "",
      quantity: "",
      max: "",
      price: "",
      description: "",
      freeTicket: false,
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await createTicket(id, {
          ticket: values,
        });

        history.push(`/events/details/${id}?tab=2`);
      } catch (error) {
        console.error({ error });
      }
      setIsLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onFreeTicketChange = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("currency", "---");
      formik.setFieldValue("price", "0.00");
    } else {
      formik.setFieldValue("currency", "EUR");
    }
    formik.handleChange(e);
  };



  const renderCurrency = () => {

    return currencies.map((el) => {
      if (el.name === "---") {
        return (
          <option style={{ display: "none" }} value={el.name} key={el.id}>
            {el.name}
          </option>
        );
      }
      return (
        <option value={el.name} key={el.id}>
          {el.name}
        </option>
      );
    });
  };

  return (
    <form className="mt-3 p-6" onSubmit={formik.handleSubmit}>
      <label className="flex items-center my-3">
        <input
          id="freeTicket"
          name="freeTicket"
          type="checkbox"
          className={`form-checkbox focus:ring focus:ring-purple-400 background border-violet focus:ring-opacity-50`}
          onChange={onFreeTicketChange}
          checked={formik.values.freeTicket}
        />
        <span className="text-white-700">Free ticket</span>
      </label>
      <div className="mt-5">
        <label htmlFor="category" className="block font-medium text-white-700">
          Currency
        </label>
        <select
          id="currency"
          name="currency"
          autoComplete="currency"
          className={`mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.currency ? "red" : "gray"
          }-300 shadow-sm focus:border-violet border-violet background focus:ring focus:ring-purple-400 focus:ring focus:ring-opacity-50 form-input`}
          onChange={formik.handleChange}
          value={formik.values.currency}
          disabled={formik.values.freeTicket}
        >
          <option value="">Choose currency</option>
          {renderCurrency()}
        </select>
      </div>
      {formik.errors.currency ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.currency}
        </span>
      ) : null}

      <label className="block my-3">
        <span className="text-white-700">Price</span>
        <input
          disabled={formik.values.freeTicket}
          id="price"
          name="price"
          type="text"
          className={`mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.price ? "red" : "gray"
          }-300 shadow-sm focus:border-violet border-violet background focus:ring focus:ring-purple-400 focus:ring focus:ring-opacity-50 form-input`}
          onChange={formik.handleChange}
          value={formik.values.price}
        />
      </label>
      {formik.errors.price ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.price}
        </span>
      ) : null}

      <label className="block my-3">
        <span className="text-white-700">Ticket type</span>
        <input
          id="type"
          name="type"
          type="text"
          className={`mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.type ? "red" : "gray"
          }-300 shadow-sm focus:border-violet border-violet background focus:ring focus:ring-purple-400 focus:ring focus:ring-opacity-50`}
          onChange={formik.handleChange}
          value={formik.values.type}
        />
      </label>
      {formik.errors.type ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.type}
        </span>
      ) : null}

      <label className="block my-3">
        <span className="text-white-700">Quantity</span>
        <input
          id="quantity"
          name="quantity"
          type="text"
          className={`mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.quantity ? "red" : "gray"
          }-300 shadow-sm focus:border-violet border-violet background focus:ring focus:ring-purple-400 focus:ring focus:ring-opacity-50`}
          onChange={formik.handleChange}
          value={formik.values.quantity}
        />
      </label>
      {formik.errors.quantity ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.quantity}
        </span>
      ) : null}

      <label className="block my-3">
        <span className="text-white-700">Max reg</span>
        <input
          id="max"
          name="max"
          type="text"
          className={`mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.max ? "red" : "gray"
          }-300 shadow-sm focus:border-violet border-violet background focus:ring focus:ring-purple-400 focus:ring focus:ring-opacity-50`}
          onChange={formik.handleChange}
          value={formik.values.max}
        />
      </label>
      {formik.errors.max ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.max}
        </span>
      ) : null}

      <div className="mt-5">
        <label
          htmlFor="description"
          className="block text-sm font-medium text-white-700"
        >
          Ticket Description
        </label>
        <div className="mt-1">
          <textarea
            id="description"
            name="description"
            rows="3"
            onChange={formik.handleChange}
            className={`shadow-sm focus:border-violet border-violet background focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-${
              formik.errors.description ? "red" : "gray"
            }-300 rounded-md`}
            value={formik.values.description}
          />
        </div>
        <p className="mt-2 text-sm text-white-500">
          Brief description for your ticket. Max number of characters - 2000.
        </p>
      </div>
      {formik.errors.description ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.description}
        </span>
      ) : null}

      <button
        className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
        type="submit"
      >
        {isLoading ? <CircleSpinLoader size="6" bgColor="white" /> : null}{" "}
        <span className="ml-3">Create</span>
      </button>
    </form>
  );
}
