import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Container } from "../../common/layouts/Container";
import { getLandingUserInfo, getUsers } from "../../API";

export function AdminPanel() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const users = await getUsers();
      setUsers(users);
    };
    fetchData();
  }, []);

  return (
    <Container withDecoration={true}>
      <div className="p-4">
        <div className="z-10 relative flex items-center">
          <Link to="/events">
            <img className="" src="/img/arrow_left.svg" alt="arrow" />
          </Link>
          <div className="flex-auto text-center text-base ext-white-700 p-6 font-bold">
            Admin panel
          </div>
        </div>

        <div>
          <ul className="flex mb-0 list-none flex-row" role="tablist">
            <li className="-mb-px last:mr-0 flex-auto text-center">
              <a
                className="text-xs text-white font-bold uppercase py-3 block leading-normal text-black border-b-2 border-bottom-violet"
                data-toggle="tab"
                href="/#"
                onClick={(e) => e.preventDefault()}
                role="tablist"
              >
                <i className="fas fa-space-shuttle text-base mr-1" />
                User Management
              </a>
            </li>
            {/*<li className="-mb-px last:mr-0 flex-auto text-center">*/}
            {/*  /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
            {/*  <a*/}
            {/*    className="text-xs font-normal uppercase py-3 block leading-normal border-b-2 border-white-200"*/}
            {/*    data-toggle="tab"*/}
            {/*    href="#"*/}
            {/*    role="tablist"*/}
            {/*  >*/}
            {/*    <i className="fas fa-space-shuttle text-base mr-1" />*/}
            {/*    Func two*/}
            {/*  </a>*/}
            {/*</li>*/}
          </ul>
        </div>
        <div className="mt-10 mx-5 text-2xl font-bold">Users</div>

        <div className="mt-10">
          <div className="mx-5 flex justify-between opacity-60">
            <p className="text-xs text-normal text-slate-300">Name</p>
            <p className="mx-10 text-center text-xs text-normal">Phone</p>
          </div>

          <>
            {users.map((e) => (
              <>
                <div className="hover:bg-purple-500">
                  <Link to={`/profile/user-info/${e.id}`}>
                    <div className="mx-5 pt-5 flex justify-between">
                      <p className="cursor-pointer text-sm font-bold">
                        {e.displayName}
                      </p>
                      <p className="text-sm text-normal">
                        {e.phone.replace(/[^\d]/g, "")}
                      </p>
                    </div>
                    <hr className="mt-5 w-full" />
                  </Link>
                </div>
              </>
            ))}
          </>
        </div>

        <button className="my-10 mx-5 bg-violet rounded flex justify-between items-center text-white text-xs px-4 py-2">
          <img src="/img/plus.svg" alt="plus" className="mr-3" width="10" /> ADD
        </button>
      </div>
    </Container>
  );
}
