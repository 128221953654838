import axios from "axios"
import env from "./config/env"
import { trigger } from "./features/custom-events/customEvents"
import { OPEN_POPUP_BACKEND_ERROR } from "./features/custom-events/constants"

const URL = env.REACT_APP_API_URL //REACT_APP_API_URL вставляется девопсом

const API = axios.create({
  baseURL: `${URL}/api/`,
})

API.interceptors.request.use((config) => {
  const token = localStorage.getItem("token")
  if (token) {
    config.headers["x-access-token"] = token
  }
  return config
})

API.interceptors.response.use(
  (r) => r,
  (error) => {
    const errMsg = error?.response?.data?.message
    trigger(OPEN_POPUP_BACKEND_ERROR, { message: errMsg })
    return Promise.reject(error)
  }
)

export const getDashboardSales = async (eventId, period = "week") => {
  const response = await API.get(`dashboard/sales/${eventId}?period=${period}`)
  return response?.data
}

export const createStripeAccount = async (user, token, personToken) => {
  const response = await API.post("promoter/createStripeAccount", {
    ...user,
    token,
    personToken,
  })
  return response?.data
}

export const updateStripeAccount = async (user) => {
  const response = await API.post("promoter/updateStripeAccount", {
    ...user,
  })
  return response?.data
}

export const getStripeAccount = async () => {
  const response = await API.get("promoter/stripeAccount")
  return response?.data
}

export const getBayTicketEventPage = async (eventId) => {
  const response = await API.get(`/events/codesForShare/${eventId}`)
  return response?.data
}

export const getDashboardTickets = async (eventId, period = "week") => {
  const response = await API.get(
    `dashboard/tickets/${eventId}?period=${period}`
  )
  return response?.data
}

export const getDashboardScanned = async (eventId, period = "week") => {
  const response = await API.get(`dashboard/scans/${eventId}?period=${period}`)
  return response?.data
}

export const getEvents = async (query = "all") => {
  const response = await API.get(`events?query=${query}`)
  return response?.data?.result
}

export const getLocales = async () => {
  const response = await API.get("locale/")
  return response?.data
}

export const getCountries = async () => {
  const response = await API.get("payment/allCountries")
  return response?.data
}

export const getCurrency = async (countryName) => {
  const response = await API.get(`currency/byCountryName/${countryName}`)
  return response?.data
}

export const createEvent = async (data) => {
  const response = await API.post("events/", {
    data: data.eventData,
    ticket: [data.ticket],
  })
  return response?.data.result
}

export const publishEvent = async (id, data) => {
  return await API.put(`events/publish/${id}`, { data })
}

export const updateEvent = async (id, data) => {
  return await API.put(`events/${id}`, { data })
}

export const deleteEvent = async (id) => {
  return await API.delete(`events/${id}`)
}

// export const getEventByDocName = async (eventDocName) => {
//   const  response = await API.get(`events/byDocName/${eventDocName}`);
//   return response;
// };

export const getEventsByShortKey = async (shortKey) => {
  const response = await API.get(`events/byShortKey/${shortKey}`)
  return response?.data?.result
}

export const getEventsCodesForShare = async (eventId) => {
  const response = await API.get(`events/CodesForShare/${eventId}`)
  return response?.data?.result
}

export const getEventWithTypeTickets = async (eventId) => {
  const response = await API.get(`events/WithTypeTickets/${eventId}`)
  return response.data
}

export const getUser = async () => {
  const response = await API.get(`promoter/`)
  return response?.data?.result
}

export const getUsers = async () => {
  const response = await API.get(`promoter/all`)
  return response?.data?.result
}

export const updateAnotherUser = async (data) => {
  const response = await API.put(`promoter/another`, data)
  return response?.data
}

export const updateUser = async (data) => {
  const response = await API.put(`promoter/`, data)
  return response?.data
}

// export const deleteUser = async () => {
//   const response = await API.delete(`promoter/`);
//   return response?.data;
// };

export const newUserSignUp = async () => {
  const response = await API.post("promoter/")
  return response?.data
}

export const getBankAccountInfo = async () => {
  const response = await API.get("Promoter/BankAccountInfo")
  return response?.data?.result
}

export const updateBankAccount = async (data) => {
  const response = await API.put(`promoter/bankAccountInfo`, data)
  return response?.data
}

export const getMobileMoneyAccount = async () => {
  const response = await API.get("Promoter/MobileMoneyAccount")
  return response?.data?.result
}

export const updateMobileMoneyAccount = async (data) => {
  const response = await API.put(`promoter/MobileMoneyAccount`, data)
  return response?.data
}

export const createTicket = async (eventId, data) => {
  const response = await API.post(`TicketsType/${eventId}`, data)
  return response?.data
}

export const updateTicket = async (eventId, ticketId, data) => {
  const response = await API.put(`TicketsType/${eventId}/${ticketId}`, data)
  return response?.data
}

export const changeUserStatus = async (userId) => {
  const response = await API.put(`promoter/another/${userId}`)
  return response?.data
}

export const deleteTicket = async (eventId, ticketId) => {
  const response = await API.delete(`TicketsType/${eventId}/${ticketId}`)
  return response?.data
}

export const getOneTicket = async (eventId, typeTicketId) => {
  const response = await API.get(`TicketsType/${eventId}/${typeTicketId}`)
  return response?.data
}

export const checkPhone = async (phone) => {
  const response = await API.post(`promoter/PhoneCheck`, { phone })
  return response?.data?.success
}

export const signIn = async (phone, code) => {
  const response = await API.post("promoter/authConfirm", { phone, code })
  return response?.data
}

export const signUp = async (phone, fullName) => {
  const response = await API.post("promoter/", { phone, fullName })
  return response?.data
}

export const getControllers = async (eventId) => {
  const response = await API.get(`/controller/event/${eventId}/`)
  return response?.data.controller
}

export const createController = async (data) => {
  const response = await API.post(`/controller/`, data)
  return response?.data.controller
}

export const updateController = async (data) => {
  const response = await API.put(`/controller/`, data)
  return response?.data.controller
}

export const deleteController = async (id) => {
  const response = await API.delete(`/controller/${id}`)
  return response?.data.controller
}

export const getAttendees = async (eventId) => {
  const response = await API.get(`/userticket/attendee/${eventId}`)
  return response?.data.attendeeList
}

export const getAttendee = async (eventId, phone) => {
  const response = await API.get(
    `/userticket/attendee?eventId=${eventId}&phone=${phone}`
  )
  return response?.data.attendeeItem
}

export const getReverseGeocode = async (latlng, key) => {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${key}`
  )
  return response.data.results
}

export const getTimeByCoords = async (latlng, key) => {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${latlng}&key=${key}&timestamp=${1644578922}`
  )
  return response.data
}
