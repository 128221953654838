import React from "react";
import PropTypes from "prop-types";

export function Container({
  bgColor = "background",
  height = "h-auto",
  withDecoration = false,
  children,
  classes = [],
}) {
  const renderDecoration = () => {
    if (withDecoration) return <div className="decoration" />;
  };
  return (
    <div
      className={`container ${bgColor} mx-auto ${height} ${classes.join(" ")}`}
    >
      {renderDecoration()}
      <div className="relative z-10">{children}</div>
    </div>
  );
}

Container.propTypes = {
  bgColor: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  classes: PropTypes.arrayOf(PropTypes.string),
};
