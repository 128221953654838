import React, { useEffect, useState } from "react";
import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";
import { ItemScanner } from "./ItemScanner";
import {
  createController,
  deleteController,
  getControllers,
  updateController,
} from "../../API";
import { Title } from "../../common/titles/Title";

export const ListScanners = ({ eventId }) => {
  const [scanners, setScanners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editId, setEditId] = useState(null);

  const onEdit = (id) => setEditId(id);
  const onUpdate = (id, phone) => {
    const data = {
      id,
      eventId,
      phone,
      fio: "null",
    };
    fetchData(() => updateController(data));
    setEditId(null);
  };
  const onDelete = (id) => {
    fetchData(() => deleteController(id));
    setEditId(null);
  };
  const onCreate = (id, phone) => {
    const data = {
      eventId,
      phone,
      fio: "null",
    };
    fetchData(() => createController(data));
    setEditId(null);
  };
  const fetchData = async (method) => {
    setIsLoading(true);
    try {
      const result = await method();
      setScanners(result);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(() => getControllers(eventId));
  }, [eventId]);

  const renderNewScanner = () => {
    if (editId === "new") {
      return (
        <ItemScanner
          id="new"
          phone=""
          onDelete={() => setEditId(null)}
          onSave={onCreate}
          editId={editId}
          onEdit={onEdit}
        />
      );
    }
    return null;
  };

  const renderList = () => {
    return scanners.map((el) => (
      <ItemScanner
        key={el.id}
        id={el.id}
        phone={el.phone}
        onDelete={onDelete}
        onSave={onUpdate}
        editId={editId}
        onEdit={onEdit}
      />
    ));
  };
  const renderContent = () => {
    if (isLoading)
      return (
        <div className="mt-10">
          <CircleSpinLoader size={20} bgColor="yellow-500" />
        </div>
      );
    return (
      <>
        {renderNewScanner()}
        {renderList()}
      </>
    );
  };
  return (
    <div>
      <Title extraStyles="pl-0 pr-0 pb-3 flex justify-between items-center">
        <div className="text-white">Scanners</div>
        <button
          onClick={() => setEditId("new")}
          className="bg-violet rounded flex justify-between items-center text-white text-xs px-4 py-2"
        >
          <img src="/img/plus.svg" alt="plus" className="mr-3" width="10" /> ADD
        </button>
      </Title>
      {/*<div className="italic text-xs mb-5">Scanners</div>*/}
      {/*<button*/}
      {/*  onClick={() => setEditId("new")}*/}
      {/*  className="bg-violet rounded flex justify-between items-center text-white text-xs px-4 py-2"*/}
      {/*>*/}
      {/*  <img src="/img/plus.svg" alt="plus" className="mr-3" width="10" /> ADD*/}
      {/*</button>*/}
      {renderContent()}
    </div>
  );
};
