import React, {useState, useEffect} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

import {Container} from "../../common/layouts/Container";
import {Title} from "../../common/titles/Title";
import {Link, useHistory} from "react-router-dom";
import {StepsCreationEvent} from "./StepsCreationEvent";
import {useEventContext} from "../../contexts/EventContext";
import {CircleSpinLoader} from "../../common/loaders/CircleSpinLoader";
import {createEvent} from "../../API";

const DisplayingErrorMessagesSchema = Yup.object().shape({
    currency: Yup.string().required("Currency name of event is required"),
    type: Yup.string().required("The type name of ticket is required"),
    quantity: Yup.number()
        .max(10000)
        .typeError("The quantity of tickets must be a number")
        .positive("The quantity of tickets must be greater than zero")
        .required("The quantity is required"),
    max: Yup.number()
        .max(10000)
        .typeError("The maximum number of tickets must be a number")
        .positive("The maximum number of tickets must be greater than zero")
        .required("The maximum number of tickets that can be purchased is required")
        .test(
            "is-greater",
            "The maximum number of tickets must equal the number of tickets available",
            function (value) {
                const {quantity} = this.parent;

                return quantity >= value;
            }
        ),
    price: Yup.number()
        .typeError("The price of tickets must be a number")
        .required("The price is required"),
    description: Yup.string()
        .required("The description is required")
        .max(2000, "Max number of characters - 2000."),
});

export function CreateEventThirdStep() {
    const history = useHistory();
    const {eventData, resetLocalEventData, saveLocalEventData, currencies, fetchCurrencies, country } =
        useEventContext();
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            currency: eventData?.ticket?.currency || "",
            type: eventData?.ticket?.type || "",
            quantity: eventData?.ticket?.quantity || "",
            max: eventData?.ticket?.max || "",
            price: eventData?.ticket?.price || "",
            description: eventData?.ticket?.description || "",
            freeTicket: eventData?.ticket?.freeTicket || false,
        },
        validationSchema: DisplayingErrorMessagesSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const {ticket, ...data} = eventData;
                await createEvent({
                    eventData: data,
                    ticket: values,
                });
                // await createTicket(eventId, {
                //   ticket: values,
                // });

                resetLocalEventData();
                history.push("/events");
            } catch (error) {
                console.error({error});
            }
            setIsLoading(false);
        },
        validateOnChange: false,
        validateOnBlur: false,
    });


    useEffect(() => {
        fetchCurrencies(eventData?.country); //возможно это не нужно
    }, [eventData]);

    const onFreeTicketChange = (e) => {
        if (e.target.checked) {
            formik.setFieldValue("currency", "---");
            formik.setFieldValue("price", "0.00");
        } else {
            formik.setFieldValue("currency", "EUR");
        }
        formik.handleChange(e);
    };

    const renderCurrency = () => {
        if (!currencies) return [];
        return currencies?.map((el) => {
            if (el.name === "---") {
                return (
                    <option style={{display: "none"}} value={el.name} key={el.id}>
                        {el.name}
                    </option>
                );
            }
            return (
                <option value={el.name} key={el.id}>
                    {el.name}
                </option>
            );
        });
    };

    return (
        <Container className="bg-white">
            <div className="p-4">
                <div className="flex items-center">
                    <Link
                        to="/create-event-second-step"
                        onClick={() => saveLocalEventData({ticket: formik.values})}
                    >
                        <img className="" src="/img/arrow_left.svg" alt="arrow"/>
                    </Link>
                    <div className="flex-auto text-center">
                        <Title text="Create Event"/>
                    </div>
                </div>

                <StepsCreationEvent
                    isFirstStepFinished={true}
                    isSecondStepFinished={true}
                    step={3}
                />

                <form className="mt-10" onSubmit={formik.handleSubmit}>
                    <label className="flex items-center my-3">
                        <input
                            id="freeTicket"
                            name="freeTicket"
                            type="checkbox"
                            className={` background border-violet form-checkbox border focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
                            onChange={onFreeTicketChange}
                            checked={formik.values.freeTicket}
                        />
                        <span className="text-white-700">Free ticket</span>
                    </label>
                    <div className="mt-5">
                        <label
                            htmlFor="currency"
                            className="block font-medium text-white-700"
                        >
                            Currency
                        </label>
                        <select
                            disabled={formik.values.freeTicket}
                            id="currency"
                            name="currency"
                            autoComplete="currency"
                            className={`background border-violet focus:ring-purple-400 focus:border-violet mt-1 p-4 border block w-full rounded-md border-${
                                formik.errors.currency ? "red" : "gray"
                            }-300 shadow-sm 0 focus:ring  focus:ring-opacity-50 form-input`}
                            onChange={formik.handleChange}
                            value={formik.values.currency}
                        >
                            <option value="">Choose currency</option>
                            {renderCurrency()}
                        </select>
                    </div>
                    {formik.errors.currency ? (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.currency}
            </span>
                    ) : null}

                    <label className="block my-3">
                        <span className="text-white-700">Price</span>
                        <input
                            disabled={formik.values.freeTicket}
                            id="price"
                            name="price"
                            type="text"
                            className={`background border-violet focus:ring-purple-400 focus:border-violet mt-1 p-4 border block w-full rounded-md border-${
                                formik.errors.price ? "red" : "gray"
                            }-300 shadow-sm  focus:ring focus:ring-opacity-50 form-input`}
                            onChange={formik.handleChange}
                            value={formik.values.price}
                        />
                    </label>
                    {formik.errors.price ? (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.price}
            </span>
                    ) : null}

                    <label className="block my-3">
                        <span className="text-white-700">Ticket type</span>
                        <input
                            id="type"
                            name="type"
                            type="text"
                            className={`background border-violet focus:ring-purple-400 focus:border-violet mt-1 p-4 border block w-full rounded-md border-${
                                formik.errors.type ? "red" : "gray"
                            }-300 shadow-sm focus:ring focus:ring-opacity-50`}
                            onChange={formik.handleChange}
                            value={formik.values.type}
                        />
                    </label>
                    {formik.errors.type ? (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.type}
            </span>
                    ) : null}

                    <label className="block my-3">
                        <span className="text-white-700">Quantity</span>
                        <input
                            id="quantity"
                            name="quantity"
                            type="text"
                            className={`background border-violet focus:ring-purple-400 focus:border-violet mt-1 p-4 border block w-full rounded-md border-${
                                formik.errors.quantity ? "red" : "gray"
                            }-300 shadow-sm focus:ring focus:ring-opacity-50`}
                            onChange={formik.handleChange}
                            value={formik.values.quantity}
                        />
                    </label>
                    {formik.errors.quantity ? (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.quantity}
            </span>
                    ) : null}

                    <label className="block my-3">
                        <span className="text-white-700">Max reg</span>
                        <input
                            id="max"
                            name="max"
                            type="text"
                            className={`background border-violet focus:ring-purple-400 focus:border-violet mt-1 p-4 border block w-full rounded-md border-${
                                formik.errors.max ? "red" : "gray"
                            }-300 shadow-sm focus:ring focus:ring-opacity-50`}
                            onChange={formik.handleChange}
                            value={formik.values.max}
                        />
                    </label>
                    {formik.errors.max ? (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.max}
            </span>
                    ) : null}

                    <div className="mt-5">
                        <label
                            htmlFor="description"
                            className="block text-sm font-medium text-white-700"
                        >
                            Ticket Description
                        </label>
                        <div className="mt-1">
              <textarea
                  id="description"
                  name="description"
                  rows="3"
                  onChange={formik.handleChange}
                  className={` background border-violet focus:ring-purple-400 focus:border-violet shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-${
                      formik.errors.description ? "red" : "gray"
                  }-300 rounded-md`}
                  value={formik.values.description}
              />
                        </div>
                        <p className="mt-2 text-sm text-white-500">
                            Brief description for your ticket. Max number of characters -
                            2000.
                        </p>
                    </div>
                    {formik.errors.description ? (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.description}
            </span>
                    ) : null}

                    <button
                        className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
                        type="submit"
                    >
                        {isLoading ? <CircleSpinLoader size="6" bgColor="white"/> : null}{" "}
                        <span className="ml-3">Create Event</span>
                    </button>
                </form>
            </div>
        </Container>
    );
}
