import React from "react";
import { OPEN_POPUP_AUTH_ERROR } from "../custom-events/constants";

import { WrapperPopup } from "./WrapperPopup";

export function AuthenticationPopupError({ message }) {
  return (
    <WrapperPopup openEventName={OPEN_POPUP_AUTH_ERROR} isSuccess={false}>
      {message}
    </WrapperPopup>
  );
}
