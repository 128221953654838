import React, { useEffect, useState } from "react";
import { ScannedDashboardWeek } from "./ScannedDashboardWeek";
import { ScannedDashboardMonth } from "./ScannedDashboardMonth";
import { ScannedDashboardYear } from "./ScannedDashboardYear";
import { getDashboardScanned, getDashboardTickets } from "../../../API";
import { TicketsDashboardWeek } from "../tickets/TicketsDashboardWeek";
import { TicketsDashboardMonth } from "../tickets/TicketsDashboardMonth";
import { TicketsDashboardYear } from "../tickets/TicketsDashboardYear";

export function Scanned({ eventId }) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [scanners, setScanners] = useState([]);

  const [period, setPeriod] = useState("week");
  const [data, setData] = useState(null);

  const fetchData = async (method) => {
    setIsLoading(true);
    try {
      const result = await method();
      setScanners(result);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(() => {
      getDashboardScanned(eventId, period).then((data) => setData(data));
    });
  }, [eventId, period]);

  const renderTabContent = () => {
    if (period === "week") {
      return (
        <div>
          <ScannedDashboardWeek data={data} />
        </div>
      );
    }
    if (period === "month") {
      return (
        <div>
          <ScannedDashboardMonth data={data} />
        </div>
      );
    }
    if (period === "year") {
      return (
        <div>
          <ScannedDashboardYear data={data} />
        </div>
      );
    }
  };

  return (
    <div>
      <nav className="flex justify-between mt-5 my-5">
        <div className="font-semibold">Scanned tickets</div>

        <ul className="flex justify-between  ">
          <div className="font-normal text-gray-400">current:</div>
          <div>
            <div className="flex justify-between">
              <button
                className={
                  period === "week"
                    ? "ml-4 font-bold text-violet"
                    : "ml-4 font-bold text-gray-400"
                }
                onClick={() => setPeriod("week")}
              >
                Week
              </button>
              <button
                className={
                  period === "month"
                    ? "ml-4 font-bold text-violet"
                    : "ml-4 font-bold text-gray-400"
                }
                onClick={() => setPeriod("month")}
              >
                Month
              </button>
              <button
                className={
                  period === "year"
                    ? "ml-4 font-bold text-violet"
                    : "ml-4 font-bold text-gray-400"
                }
                onClick={() => setPeriod("year")}
              >
                Year
              </button>
            </div>
          </div>
        </ul>
      </nav>

      <div className="w-full">{renderTabContent()}</div>
    </div>
  );
}

export default Scanned;
