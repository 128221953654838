export function ListInfoLoader() {
  return (
    <div className="mt-5 mx-6">
      {[0, 1, 2].map((value) => (
        <div
          key={value}
          className="border border-violet shadow rounded-md p-4 max-w-sm mx-auto mt-4"
        >
          <div className="animate-pulse flex space-x-4">
            <div className="rounded-full bg-violet h-12 w-12"></div>
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-violet rounded w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-violet rounded"></div>
                <div className="h-4 bg-violet rounded w-5/6"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
