import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {current} from "@reduxjs/toolkit";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      // display: true,
      // text: 'test text',
    },
  },
  scales: {
    x: {
      grid: {
        color: "transparent",
        borderColor: "transparent",
        tickColor: "transparent",
      },
    },
    y: {
      grid: {
        color: "transparent",
        borderColor: "transparent",
        tickColor: "transparent",
      },
    },
  },
};

const labels = ["2020", "2021", "2022"];

export const testData = {
  labels,
  datasets: [
    {
      data: [70, 60, 50],
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000, })),
      backgroundColor: "rgba(167, 74, 253, 0.5)",
      borderRadius: 5,
    },
  ],
};

export function SalesDashboardYear({ data, currentCurrency }) {
  if (!data) {
    return null;
  }

  const generatedBarData = () => {
    const values = new Array(3);

    values.fill(0);

    data[currentCurrency]?.forEach((e) => {
      values[e.point - 2020] = e.amount;
    });

    return {
      labels,
      datasets: [
        {
          data: values,
          // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000, })),
          backgroundColor: "rgba(167, 74, 253, 0.5)",
          borderRadius: 5,
        },
      ],
    };
  };
  return <Bar options={options} data={generatedBarData()} />;
}
