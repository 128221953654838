import React from "react";
import { Link } from "react-router-dom";

import { Container } from "../../common/layouts/Container";

export function Error404() {
  return (
    <Container>
      <section className="flex items-center justify-center h-screen">
        <div class="text-center">
          <h1 class="font-bold text-6xl">404</h1>

          <div class="contant_box_404">
            <h3 class="h2">Look like you're lost</h3>
            <p>the page you are looking for not avaible!</p>

            <Link to="/" className="text-white-500">
              Go to Home
            </Link>
          </div>
        </div>
      </section>
    </Container>
  );
}
