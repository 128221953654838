import React from "react";

import { OPEN_POPUP_EVENT_SIGNIN_SUCCESS } from "../custom-events/constants";
import { WrapperPopup } from "./WrapperPopup";

export function SignInPopupSuccess() {
  return (
    <WrapperPopup openEventName={OPEN_POPUP_EVENT_SIGNIN_SUCCESS}>
      You have signed in
    </WrapperPopup>
  );
}
