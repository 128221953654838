import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Title } from "../../common/titles/Title";
import { UpdateAccountPopupSuccess } from "../popups/UpdateAccountPopupSuccess";
import { getMobileMoneyAccount } from "../../API";
import { MobileMoneyInformationForm } from "./MobileMoneyInformationForm";
import { GroupFormLoader } from "../../common/loaders/GroupFormLoader";

export function MobileMoneyInformation() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const result = await getMobileMoneyAccount();
        setData(result);
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    }

    fetchData();
  }, []);

  return (
    <div className="p-4">
      <UpdateAccountPopupSuccess />
      <div className="flex items-center">
        <Link to="/events">
          <img className="" src="/img/arrow_left.svg" alt="arrow" />
        </Link>
        <div className="flex-auto text-center">
          <Title text="Mobile Money Information" />
        </div>
      </div>
      {isLoading ? (
        <GroupFormLoader />
      ) : (
        <MobileMoneyInformationForm data={data || {}} />
      )}
    </div>
  );
}
