import React, { useState } from "react";

export const ItemScanner = ({
  id,
  phone,
  editId,
  onEdit,
  onDelete,
  onSave,
}) => {
  const [localPhone, setLocalPhone] = useState(phone || "");
  const isDisabled = editId !== id;
  const renderButtons = () => {
    if (!isDisabled) {
      return (
        <>
          <button className="w-8 h-8 flex justify-center items-center mr-3">
            <img
              className="m-0"
              src="/img/check.svg"
              width="14"
              alt=""
              onClick={() => {
                onSave(id, localPhone);
                setLocalPhone(phone);
              }}
            />
          </button>
          <button
            className="w-8 h-8 flex justify-center items-center"
            onClick={() => onDelete(id)}
          >
            <img
              className="m-0"
              src="/img/delete_button.svg"
              width="20"
              alt=""
            />
          </button>
        </>
      );
    }
    return (
      <button
        className="w-8 h-8 flex justify-center items-center"
        onClick={() => onEdit(id)}
      >
        <img className="m-0" src="/img/edit_button.svg" width="20" alt="" />
      </button>
    );
  };
  return (
    <div className="w-full py-2.5 border-b border-gray-200 flex justify-between items-center">
      <input
        disabled={isDisabled}
        value={isDisabled ? phone : localPhone}
        onChange={(e) => setLocalPhone(e.target.value)}
        type="text"
        className="border-violet background h-10  p-2.5 rounded border disabled:border-none mr-6"
      />
      <div className="flex items-center justify-between">{renderButtons()}</div>
    </div>
  );
};
