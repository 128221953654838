import React from "react";
import { useParams } from "react-router-dom";

import { HeaderDetails } from "../../common/header/HeaderDetails";
import { Container } from "../../common/layouts/Container";
import { CreateTicketForm } from "./CreateTicketForm";

export function CreateNewTicket() {
  const { id } = useParams();
  return (
    <Container>
      <HeaderDetails
        pathReturn={`/events/details/${id}?tab=2`}
        textTitle="New Ticket"
      />
      <CreateTicketForm />
    </Container>
  );
}
