import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { timeSince } from "../../common/helper/timeSince";
import { Title } from "../../common/titles/Title";
import { GetAccountDataPopupError } from "../popups/GetAccountDataPopupError";
import { getUser } from "../../API";

export function EventInfo({ data }) {
  const params = useParams();
  const history = useHistory();

  const handleClickOnEditButton = () => {
    history.push(`/events/update/${data.id}`);
  };

  const timeAgo = Date.now() - data.createdAtTimestamp;
  const startAt = `${data.startDate} ${data.startTime}`;
  const endAt = `${data.endDate} ${data.endTime}`;
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUser();
      setUser(data);
    };
    fetchData();
  }, []);

  return (
    <div>
      <GetAccountDataPopupError />
      <Title extraStyles="flex justify-between">
        <span className="font-bold">Info</span>
        <button
          type="button"
          onClick={handleClickOnEditButton}
          disabled={data.published}
          className="edit-button"
        >
          <img src="/img/edit_button.svg" alt="edit icon" />
        </button>
      </Title>
      <div className="p-6">
        <h4 className="text-xs text-gray-400">Basic Details</h4>
        <p className="mt-3">{data.name}</p>
        <p className="mt-3">{data.category}</p>
        <p className="mt-3">
          {startAt} - {endAt}
        </p>
        <p className="mt-3">{data.description}</p>
        <h4 className="mt-6 text-xs text-gray-400">Venue and Host</h4>
        <p className="mt-3">{data.venueName}</p>
        <p className="mt-3">{data.address}</p>
        <p className="mt-3">{data.country}</p>
        <h4 className="mt-6 text-xs text-gray-400">
          Created {timeSince(new Date(Date.now() - timeAgo))} ago
        </h4>
      </div>
    </div>
  );
}
