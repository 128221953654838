import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEventContext } from "../../contexts/EventContext";

export default function ListTickets({ typeTickets = [] }) {
  const history = useHistory();
  const { id } = useParams();
  const { saveLocalTypeTicketSelected } = useEventContext();

  const handleClickOnTypeTicket = (typeTicket) => {
    saveLocalTypeTicketSelected(typeTicket);
    history.push(`/events/${id}/tickets/info`);
  };

  const renderTickets = () => {
    return typeTickets.map((typeTicket) => {
      return (
        <div
          key={typeTicket.id}
          className="flex items-center justify-between border-b border-purple-200 hover:bg-purple-500 text-sm pt-5 pb-5 cursor-pointer text-center"
          onClick={() => handleClickOnTypeTicket(typeTicket)}
        >
          <div className="flex-1 text-center">{typeTicket.type}</div>
          <div className="flex-1 text-center">{typeTicket.quantity}</div>
          <div className="flex-1 text-center">
            {(typeTicket.price || "") + (typeTicket.currency ? " " + typeTicket.currency : "")}
          </div>
          <div className="flex-1 text-center">{typeTicket.soldTickets}</div>
        </div>
      );
    });
  };

  return (
    <div className="mt-10 w-full mb-16">
      <div className="flex justify-between text-white-400 text-xs mb-5 ">
        <div className="flex-1 text-center">Type</div>
        <div className="flex-1 text-center">Quantity</div>
        <div className="flex-1 text-center">Price</div>
        <div className="flex-1 text-center">Sold</div>
      </div>
      {renderTickets()}
    </div>
  );
}
