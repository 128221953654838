import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import _ from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    title: {
      // display: true,
      // text: 'test text',
    },
  },
  scales: {
    x: {
      grid: {
        color: "transparent",
        borderColor: "transparent",
        tickColor: "transparent",
      },
    },
    y: {
      grid: {
        color: "transparent",
        borderColor: "transparent",
        tickColor: "transparent",
      },
    },
  },
};

const labels = ["S", "M", "T", "W", "T", "F", "S"];

export const testData = {
  labels,
  datasets: [
    {
      label: "scanner 1",
      data: [90],
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000, })),
      backgroundColor: "rgba(245, 157, 0, 1)",
      borderRadius: 5,
    },
    {
      label: "scanner 2",
      data: [190],
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000, })),
      backgroundColor: "rgba(167, 74, 253, 0.5)",
      borderRadius: 5,
    },
    {
      label: "scanner 3",
      data: [290],
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000, })),
      backgroundColor: "rgba(167, 74, 253, 0.2)",
      borderRadius: 5,
    },
  ],
};

export function ScannedDashboardWeek({ data }) {
  if (!data) {
    return null;
  }

  const generatedBarData = () => {
    const valuesGroups = _.groupBy(data.scans, "controller");

    const groups = {};

    for (const [key, value] of Object.entries(valuesGroups)) {
      const values = new Array(7);
      values.fill(0);
      value.forEach((e) => {
        values[e.point] = e.count;
      });
      groups[key] = values;
    }

    let colors = [
      {
        borderColor: "rgba(167, 74, 253, 1)",
        backgroundColor: "rgba(167, 74, 253, 1)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.8)",
        backgroundColor: "rgba(167, 74, 253, 0.8)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.7)",
        backgroundColor: "rgba(167, 74, 253, 0.7)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.6)",
        backgroundColor: "rgba(167, 74, 253, 0.6)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.5)",
        backgroundColor: "rgba(167, 74, 253, 0.5)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.4)",
        backgroundColor: "rgba(167, 74, 253, 0.4)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.3)",
        backgroundColor: "rgba(167, 74, 253, 0.3)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.2)",
        backgroundColor: "rgba(167, 74, 253, 0.2)",
      },
    ];

    const datasets = [];

    const colorsLen = colors.length;
    let i = 0;
    for (const [key, value] of Object.entries(valuesGroups)) {

      datasets.push({
        label: key,
        data: groups[key],
        borderColor: colors[i % colorsLen].borderColor,
        backgroundColor: colors[i % colorsLen].backgroundColor,
        tension: 0.4,
        pointStyle: "line",
      });
      i = i + 1;
    }

    return {
      labels,
      datasets,
    };
  };

  return <Bar options={options} data={generatedBarData()} />;
}
