import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Container } from "../../common/layouts/Container";
import {
  changeUserStatus,
  getUsers,
  updateAnotherUser,
  updateUser,
} from "../../API";
import { useFormik } from "formik";
import { trigger } from "../custom-events/customEvents";
import { OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS } from "../custom-events/constants";
import * as Yup from "yup";
import { UpdateAccountPopupSuccess } from "../popups/UpdateAccountPopupSuccess";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  fullName: Yup.string().required("Full name is required"),
  phone: Yup.string().required("Your phone number is required"),
  email: Yup.string().email("Invalid email").required("Your email is required"),
});

export function UpdateUserInfo() {
  const params = useParams();

  const [users, setUsers] = useState([]);
  const [, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const users = await getUsers();
      setUsers(users);
    };
    fetchData();
  }, []);

  const user = users.find((e) => e.id === Number(params.userId));

  const formik = useFormik({
    initialValues: {
      fullName: user?.displayName || "",
      phone: user?.phone || "",
      email: user?.email || "",
      accessLevel: user?.accessLevel || "",
      eventSize: user?.eventSize || "",
      eventCount: user?.eventCount || "",
      eventPeriod: user?.eventPeriod,
      trashCountry: user?.trashCountry || "",
      about: user?.about || "",
    },
    enableReinitialize: true,
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await updateAnotherUser({
          id: params.userId,
          phone: values.phone,
          email: values.email,
          displayName: values.fullName,
          accessLevel: values.accessLevel,
          eventSize: values.eventSize,
          eventCount: values.eventCount,
          eventPeriod: values.eventPeriod,
          trashCountry: values.trashCountry,
          about: values.about,
        });

        // firebaseAuth.currentUser.reload();
        trigger(OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS);
      } catch (error) {
      }
      setIsLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    formik.setFieldValue("period", user?.eventPeriod);
  }, [user]);

  return (
    <Container withDecoration={true}>
      <div className="p-4">
        <UpdateAccountPopupSuccess />
        <div className="flex items-center">
          <Link to={`/profile/user-info/${params.userId}`}>
            <img className="" src="/img/arrow_left.svg" alt="arrow" />
          </Link>
          <div className="flex-auto text-center text-base ext-white-700 p-6 font-bold">
            Update {user?.displayName}
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="">
            <div className="">
              <label className="block mt-5">
                <span className="text-white">Phone number</span>
                <input
                  id="phone"
                  name="phone"
                  type="phone"
                  className={`background border-violet mt-1 p-3 border block w-full rounded-md border-${
                    formik.errors.phone ? "red" : "gray"
                  }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
                  placeholder="Example : +233 01 2345 678"
                  onChange={formik.handleChange}
                  value={formik.values.phone.replace(/[^\d]/g, '')}
                />
              </label>
              {formik.errors.phone ? (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {formik.errors.phone}
                </span>
              ) : null}
            </div>

            <div>
              <label className="block mt-5">
                <span className="text-white">Email</span>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className={`background border-violet mt-1 p-3 border block w-full rounded-md border-${
                    formik.errors.email ? "red" : "gray"
                  }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
                  placeholder={`Example : ${user?.email || 'your@mail.com'}`}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </label>
              {formik.errors.email ? (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {formik.errors.email}
                </span>
              ) : null}

              <div>
                <div className=" mt-5 text-xs text-normal text-slate-300">
                  Event Size
                </div>
                {/*<div className="flex justify-between w-40 border rounded-md border-gray-300 focus:ring focus:ring-yellow-400 focus:ring-opacity-50">*/}
                {/*    <p className="px-2 pt-0.5 pb-0.5 mx-0.5 my-1.5 text-sm text-normal">8</p>*/}
                {/*    <img className="mx-2" src="/img/arrow_bottom.svg" alt="arrow"/>*/}
                {/*</div>*/}
                <select
                  id="eventSize"
                  name="eventSize"
                  autoComplete="eventSize"
                  className={`text-sm flex p-3 mt-1 justify-between w-40 border rounded-md border-violet background shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
                  onChange={formik.handleChange}
                  value={formik.values.eventSize}
                >
                  <option value="10-100">10-100</option>
                  <option value="101-500">101-500</option>
                  <option value="501+">501+</option>
                  <option value="not sure yet">not sure yet</option>
                </select>
              </div>
              <div className=" mt-5 text-xs text-normal text-slate-300">
                Number of events
              </div>
              <input
                id="eventCount"
                name="eventCount"
                autoComplete="eventCount"
                className={`text-sm flex p-3 mt-1 justify-between w-40 border rounded-md border-violet background shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
                onChange={formik.handleChange}
                value={formik.values.eventCount}
              />
              <div className=" ">
                <div className="radio flex my-2.5">
                  <div className="radio">
                    <input
                      id="month"
                      type="radio"
                      name="eventPeriod"
                      onChange={formik.handleChange}
                      value="month"
                      checked={formik.values.eventPeriod === "month"}
                    />
                    <label className="text-sm ml-2.5 mr-9 " htmlFor="month">
                      Per Month
                    </label>
                  </div>
                  <div className="radio mx-9">
                    <input
                      id="year"
                      type="radio"
                      name="eventPeriod"
                      onChange={formik.handleChange}
                      value="year"
                      checked={formik.values.eventPeriod === "year"}
                    />
                    <label className="text-sm ml-2.5" htmlFor="year">
                      Per Year
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <label className="block mt-5">
                  <span className="text-white">Country</span>
                  <input
                    id="trashCountry"
                    name="trashCountry"
                    type="trashCountry"
                    className={`background border-violet mt-1 p-3 border block w-full rounded-md shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
                    placeholder="Example : Ghana"
                    onChange={formik.handleChange}
                    value={formik.values.trashCountry}
                  />
                </label>
                {formik.errors.trashCountry ? (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {formik.errors.trashCountry}
                  </span>
                ) : null}
              </div>
              <div>
                <div className=" mt-5 text-xs text-normal text-slate-300">
                  Status
                </div>
                {/*<div className="flex justify-between border rounded-md border-gray-300">*/}
                {/*    <p className="px-2 pt-0.5 pb-0.5 mx-0.5 my-1.5 text-sm text-normal">Access On</p>*/}
                {/*    <img className="mx-2" src="/img/arrow_bottom.svg" alt="arrow"/>*/}
                {/*</div>*/}
                <label>
                  <select
                    id="accessLevel"
                    name="accessLevel"
                    autoComplete="accessLevel"
                    className={`flex justify-between p-3 border rounded-md border-violet background shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-400 text-sm`}
                    onChange={formik.handleChange}
                    value={formik.values.accessLevel}
                  >
                    <option value="Active">Access On</option>
                    <option value="Inactive">Access Off</option>
                    <option value="Verified">Verified</option>
                  </select>
                </label>
              </div>
              <div>
                <div className=" mt-5 text-xs text-normal text-slate-300">
                  Description
                </div>
                {/*<div className="">*/}
                {/*    <p className="mx-1 my-1 text-sm text-normal">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus dui vel felis purus at nulla viverra. Ornare ultrices nullam lorem lectus natoque ullamcorper arcu at pulvinar. Erat varius turpis id volutpat, ultrices imperdiet.</p>*/}
                {/*</div>*/}
                <input
                  className={`background border-violet mt-5 p-3 border block w-full rounded-md shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
                  type="text"
                  name="about"
                  onChange={formik.handleChange}
                  value={formik.values.about}
                />
              </div>
            </div>
            {/*<Link to="/profile/user-info">*/}
            <button className="mt-5 mb-7 w-full p-2.5 bg-violet rounded flex justify-between text-center text-white text-xs">
              <p className="text-sm font-bold mx-auto">Update</p>
            </button>
            {/*</Link>*/}
          </div>
        </form>
      </div>
    </Container>
  );
}
