export const LANDING_URL =
  process.env.REACT_APP_API_URL_LANDING ||
  "https://kawris-landing.bots.winsolutions.ru";
export const REACT_APP_API_URL =
  process.env.REACT_APP_API_URL_BACK ||
  "https://kawris-back.bots.winsolutions.ru";
export const REACT_APP_KAWRIS =
  process.env.REACT_APP_KAWRIS || "https://kawris.bots.winsolutions.ru/";

export const STRIPE_PK_KEY = process.env.REACT_APP_STRIPE_PK_KEY || "";

export const env = {
  LANDING_URL,
  REACT_APP_API_URL,
  REACT_APP_KAWRIS,
  STRIPE_PK_KEY,
};

export default env;
