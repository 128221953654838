import React, { useState, useEffect } from "react";
import {
  WhatsappShareButton,
  TelegramShareButton,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";
import { useEventContext } from "../../contexts/EventContext";

import { OPEN_POPUP_QRCODE } from "../custom-events/constants";

import { WrapperPopup } from "./WrapperPopup";
import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";

import { getEventsCodesForShare } from "../../API";
import { Link, useParams } from "react-router-dom";

export function QRCodePopup({ eventId }) {
  const [codeData, setCodeData] = useState(null);
  const { eventData } = useEventContext();
  const { id } = useParams();
  const [shortLink, setShortLink] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      getEventsCodesForShare(id).then((e) => {
        setShortLink(e.shortLink)
      });

    };
    if (id) {
      fetchData();
    }
  }, [id]);


  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const result = await getEventsCodesForShare(id);
        setCodeData(result);
      } catch (err) {
      }
    };
    const id = eventData?.event?.id || eventData?.id;
    if (id) {
      fetchData(id);
    }
  }, [eventData]);

  if (eventData.event && !eventData.event.published && !eventData.published) {
    return (
      <WrapperPopup isPopupSuccess={false} openEventName={OPEN_POPUP_QRCODE}>
        <div className="font-bold">
          Sorry, You must publish your event before sharing Qrcode.
        </div>
      </WrapperPopup>
    );
  }

  if (!codeData) {
    return (
      <WrapperPopup isPopupSuccess={false} openEventName={OPEN_POPUP_QRCODE}>
        <CircleSpinLoader size="12" bgColor="black" />
      </WrapperPopup>
    );
  }


  return (
    <WrapperPopup isPopupSuccess={false} openEventName={OPEN_POPUP_QRCODE}>
      <div className="flex justify-center items-center flex-wrap">
        <img
          src={codeData.qrCode}
          width="148px"
          className="mr-5 ml-5 "
          alt="QR code"
        />
        <TelegramShareButton
          url={codeData.shortLink}
          quote="An event is ready for you, don't delay to take your places ;)"
          hashtag={"#kawris"}
          className="flex justify-center items-center mt-5 w-full rounded p-2 focus:border-transparent outline-none"
        >
          <TelegramIcon size={36} round />
          <span className="pl-3">Share with Telegram</span>
        </TelegramShareButton>
        <WhatsappShareButton
          url={codeData.shortLink}
          hashtags={["kawris", "events"]}
          className="flex justify-center items-center mt-5 w-full rounded p-2"
        >
          <WhatsappIcon size={36} round />
          <span className="pl-3">Share with WhatsApp</span>
        </WhatsappShareButton>
      </div>

      <a target="_blank" rel="noopener noreferrer" href={`${codeData.shortLink}`}>
        <div className="cursor-pointer p-4 text-violet m-7 border-violet rounded-md">
          view the event page
        </div>
      </a>

      <div className="mt-3">
        <form>
          <label className="mt-5 flex justify-center items-center">
            <input
              type="text"
              disabled
              className={`background border-violet p-4  block w-full rounded-md shadow-sm focus:border-yellow-500 focus:ring focus:ring-yellow-400 focus:ring-opacity-50`}
              style={{
                maxWidth: 200,
                height: 60,
                borderRight: "none",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              value={shortLink}
            />
            <button
              type="button"
              className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-violet border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-yellow-500`}
              style={{
                maxWidth: 150,
                height: 60,
                borderLeft: "none",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                navigator.clipboard.writeText(shortLink)
              }
              }
            >
              Copy to clipboard
            </button>
          </label>
        </form>
      </div>
    </WrapperPopup>
  );
}
