import firebase from "firebase";

// const {
//     REACT_APP_FIREBASE_API_KEY,
//     REACT_APP_AUTH_DOMAIN,
//     REACT_APP_PROJECT_ID,
//     REACT_APP_STORAGE_BUCKET,
//     REACT_APP_MESSAGING_SENDER_ID,
//     REACT_APP_APP_ID,
//     REACT_APP_MEASUREMENT_ID,
// } = process.env;

export const firebaseConfig = {
  // apiKey: REACT_APP_FIREBASE_API_KEY,
  // authDomain: REACT_APP_AUTH_DOMAIN,
  // projectId: REACT_APP_PROJECT_ID,
  // storageBucket: REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  // appId: REACT_APP_APP_ID,
  // measurementId: REACT_APP_MEASUREMENT_ID,

  apiKey: "AIzaSyCMdsQXyupC2cvIOTIrct4ZDMWjVk3iZoE",
  authDomain: "kawris-2ac37.firebaseapp.com",
  projectId: "kawris-2ac37",
  storageBucket: "kawris-2ac37.appspot.com",
  messagingSenderId: "285246694903",
  appId: "1:285246694903:web:0561ae8453f13b98ec7af2",
  measurementId: "G-KZJHWWEF4B",
};

// console.log({ firebaseConfig, env: process.env });

firebase.initializeApp(firebaseConfig);

export const firebaseAuth = firebase.auth();
export const firestore = firebase.firestore();
export const firebaseFnc = firebase.functions();
export default firebase;
