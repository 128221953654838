import React from "react";

export function EmptyEvent() {
  return (
    <div className="flex flex-col justibfy-center items-center pt-10">
      <img className="h-30 w-30" src="/img/calendar.svg" alt="calendar" />
      <p className="px-6 text-center pt-4">
        You don't have any events yet, create your first event.
      </p>
    </div>
  );
}
