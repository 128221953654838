import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import _ from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    title: {
      display: true,
    },
  },
  scales: {
    x: {
      grid: {
        color: "transparent",
        borderColor: "transparent",
        tickColor: "transparent",
      },
    },
    y: {
      grid: {
        color: "transparent",
        borderColor: "transparent",
        tickColor: "transparent",
      },
    },
  },
};

const labels = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];

export const testData = {
  labels,
  datasets: [
    {
      label: "Early bird",
      data: [2, 6, 8, 4, 10, 1, 3, 2, 6, 8, 4, 10],
      borderColor: "rgba(245, 157, 0, 1)",
      backgroundColor: "rgba(245, 157, 0, 1)",
      tension: 0.4,
      pointStyle: "line",
    },
    {
      label: "Standart",
      data: [1, 4, 3, 5, 5, 10, 7, 2, 6, 8, 4, 10],
      borderColor: "rgba(167, 74, 253, 0.5)",
      backgroundColor: "rgba(167, 74, 253, 0.5)",
      tension: 0.4,
      pointStyle: "line",
    },
    {
      label: "Last chance",
      data: [4, 6, 7, 9, 1, 5, 6, 2, 6, 8, 4, 10],
      borderColor: "rgba(245, 157, 0, 0.2)",
      backgroundColor: "rgba(245, 157, 0, 0.2)",
      tension: 0.4,
      pointStyle: "line",
    },
  ],
};

export function TicketsDashboardMonth({ data }) {
  if (!data) {
    return null;
  }

  const generatedBarData = () => {
    const valuesGroups = _.groupBy(data.tickets, "ticketName");

    const groups = {};

    for (const [key, value] of Object.entries(valuesGroups)) {
      const values = new Array(12);
      values.fill(0);
      value.forEach((e) => {
        values[e.point] = e.ticketCount;
      });
      groups[key] = values;
    }

    let colors = [
      {
        borderColor: "rgba(167, 74, 253, 1)",
        backgroundColor: "rgba(167, 74, 253, 1)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.5)",
        backgroundColor: "rgba(167, 74, 253, 0.5)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.2)",
        backgroundColor: "rgba(167, 74, 253, 0.2)",
      },
    ];

    const datasets = [];

    const colorsLen = colors.length;

    let i = 0;
    for (const [key, value] of Object.entries(valuesGroups)) {

      datasets.push({
        label: key,
        data: groups[key],
        borderColor: colors[i % colorsLen].borderColor,
        backgroundColor: colors[i % colorsLen].backgroundColor,
        tension: 0.4,
        pointStyle: "line",
      });
      i = i + 1;
    }

    return {
      labels,
      datasets,
    };
  };

  return <Line options={options} data={generatedBarData()} />;
}
