import React from "react";
import { defaultMessageError, defaultMessageErrorNotVerifiedUser } from "../../common/helper/codeErrorFirebaseAuth";

import { OPEN_POPUP_EVENT_GET_ACCOUNT_DATA_ERROR } from "../custom-events/constants";
import { WrapperPopup } from "./WrapperPopup";

export function GetAccountDataPopupError() {
  return (
    <WrapperPopup
      openEventName={OPEN_POPUP_EVENT_GET_ACCOUNT_DATA_ERROR}
      isSuccess={false}
      notAuth={defaultMessageErrorNotVerifiedUser}
    >
      {defaultMessageErrorNotVerifiedUser}
    </WrapperPopup>
  );
}
