import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export function Title({
  size = "text-2xl",
  textColor = "text-white",
  padding = "p-6",
  fontStyle = "font-bold",
  /** @deprecated */
  text = "",
  extraStyles,
  children,
}) {
  const styles = classNames(size, textColor, padding, fontStyle, extraStyles);

  return <h1 className={styles}>{text || children}</h1>;
}

Title.propTypes = {
  size: PropTypes.string,
  textColor: PropTypes.string,
  padding: PropTypes.string,
  fontStyle: PropTypes.string,
  /** @deprecated */
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
