import React from "react";
import { Redirect, Route } from "react-router-dom";
import {useAuth} from "../../../contexts/AuthContext";
import env from "../../../config/env";

export function AuthRoute(props) {
    const {isAuth} = useAuth();
    const {type} = props;

    if (type === "guest" && isAuth) {
        return <Redirect to="/events"/>;
    }
    // else
    if (type === "private" && !isAuth) {
        return window.location.href = env.LANDING_URL //<Redirect to="/sign-in" />;
    }
    if (type === "share" && isAuth) {
        // return <Redirect to={`/${eventId}`} />;
    }

    return <Route {...props} />;
}
