import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { compareDesc } from "date-fns";

import { Container } from "../../common/layouts/Container";
import { Title } from "../../common/titles/Title";
import { Link, useHistory } from "react-router-dom";
import { StepsCreationEvent } from "./StepsCreationEvent";
import { useEventContext } from "../../contexts/EventContext";
import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  name: Yup.string().required("The name of event is required"),
  localeId: Yup.string().required("The language is required"),
  category: Yup.string().required("The category is required"),
  startDate: Yup.string().required("Start date is required"),
  startTime: Yup.string().required("Start time is Required"),
  endDate: Yup.string().required("End date is required"),
  endTime: Yup.string()
    .required("end time cannot be empty")
    .test(
      "is-greater",
      "The end date should be after the start date",
      function (value) {
        const { startTime, startDate, endDate } = this.parent;

        if (!startTime || !value || !startDate || !endDate) {
          return false;
        }
        const result = compareDesc(
          new Date(
            `${startDate.replace(/\./g, "/").replace(/-/g, "/")} ${startTime}`
          ),
          new Date(`${endDate.replace(/\./g, "/").replace(/-/g, "/")} ${value}`)
        );

        return result === 1;
      }
    ),
  description: Yup.string()
    .required("Description is required")
    .max(2000, "Max number of characters - 2000."),
});

export function CreateEvent() {
  const { eventData, saveLocalEventData, locales } = useEventContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      name: eventData.name || "",
      localeId: eventData.localeId || "",
      category: eventData.category || "",
      startDate: eventData.startDate || "",
      startTime: eventData.startTime || "",
      endDate: eventData.endDate || "",
      endTime: eventData.endTime || "",
      description: eventData.description || "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      saveLocalEventData(values);

      setIsLoading(false);
      history.push("/create-event-second-step");
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const renderLocaleOptions = () =>
    locales.map((l) => (
      <option value={l.id} key={l.id}>
        {l.name}
      </option>
    ));

  return (
    <Container className="bg-white">
      <div className="p-4">
        <div className="flex items-center">
          <Link to="/events">
            <img className="" src="/img/arrow_left.svg" alt="arrow" />
          </Link>
          <div className="flex-auto text-center">
            <Title text="Create Event" />
          </div>
        </div>

        <StepsCreationEvent />

        <form className="mt-10" onSubmit={formik.handleSubmit}>
          <label className="block">
            <span className="text-white-700">Name</span>
            <input
              id="name"
              name="name"
              type="text"
              className={`mt-1 p-4 border border-violet background block w-full rounded-md border-${
                formik.errors.name ? "red" : "gray"
              }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </label>
          {formik.errors.name ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.name}
            </span>
          ) : null}

          <div className="mt-5">
            <label
              htmlFor="localeId"
              className="block font-medium text-white-700"
            >
              Language
            </label>
            <select
              id="localeId"
              name="localeId"
              autoComplete="localeId"
              className={`mt-1 p-4 border background border-violet block w-full rounded-md border-${
                formik.errors.localeId ? "red" : "gray"
              }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.localeId}
            >
              <option value="">Choose language</option>
              {renderLocaleOptions()}
            </select>
          </div>
          {formik.errors.localeId ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.localeId}
            </span>
          ) : null}
          <div className="mt-5">
            <label
              htmlFor="category"
              className="block font-medium text-white-700"
            >
              Category
            </label>
            <select
              id="category"
              name="category"
              autoComplete="category"
              className={`mt-1 p-4 background border-violet border block w-full rounded-md border-${
                formik.errors.category ? "red" : "gray"
              }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.category}
            >
              <option value="">Choose category</option>
              <optgroup label="Corporate events">
                <option value="seminar">Seminar</option>
                <option value="conference">Conference</option>
                <option value="trade show">Trade show</option>
              </optgroup>
              <optgroup label="Social events">
                <option value="themed party">Themed party</option>
                <option value="interactive performance">
                  Interactive performance
                </option>
                <option value="summit">Summit</option>
              </optgroup>
              <optgroup label="Fundraising event">
                <option value="sponsored sporting event">
                  Sponsored sporting event
                </option>
                <option value="gala dinner">Gala dinner</option>
              </optgroup>
              <optgroup label="Festivals">
                <option value="music festival">Music festival</option>
                <option value="food festival">Food festival</option>
              </optgroup>
              <optgroup label="Community events">
                <option value="street party">Street party</option>
                <option value="pop up event">Pop up event</option>
              </optgroup>
            </select>
          </div>
          {formik.errors.category ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.category}
            </span>
          ) : null}

          <label className="block mt-5">
            <span className="text-white-700">Start date</span>
            <input
              id="startDate"
              name="startDate"
              type="date"
              className={`mt-1 border-violet background py-4 pl-4 border block w-full rounded-md border-${
                formik.errors.startDate ? "red" : "gray"
              }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.startDate}
            />
          </label>
          {formik.errors.startDate ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.startDate}
            </span>
          ) : null}

          <label className="block mt-5">
            <span className="text-white-700">Start time</span>
            <input
              id="startTime"
              name="startTime"
              type="time"
              className={`mt-1 background border-violet py-4 pl-4 border block w-full rounded-md border-${
                formik.errors.startTime ? "red" : "gray"
              }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.startTime}
            />
          </label>
          {formik.errors.startTime ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.startTime}
            </span>
          ) : null}

          <label className="block mt-5">
            <span className="text-white-700">End date</span>
            <input
              id="endDate"
              name="endDate"
              type="date"
              className={`background border-violet mt-1 py-4 pl-4 border block w-full rounded-md border-${
                formik.errors.endDate ? "red" : "gray"
              }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.endDate}
            />
          </label>
          {formik.errors.endDate ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.endDate}
            </span>
          ) : null}

          <label className="block mt-5">
            <span className="text-white-700">End time</span>
            <input
              id="endTime"
              name="endTime"
              type="time"
              className={`background mt-1 py-4 pl-4 border border-violet block w-full rounded-md border-${
                formik.errors.endTime ? "red" : "gray"
              }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.endTime}
            />
          </label>
          {formik.errors.endTime ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.endTime}
            </span>
          ) : null}

          <div className="mt-5">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-white-700"
            >
              Description
            </label>
            <div className="mt-1">
              <textarea
                id="description"
                name="description"
                rows="3"
                className={`background shadow-sm focus:ring-indigo-500 border-violet focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-${
                  formik.errors.description ? "red" : "gray"
                }-300 rounded-md`}
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </div>
            <p className="mt-2 text-sm text-white-500">
              Brief description for your event. Max number of characters - 2000.
            </p>
          </div>
          {formik.errors.description ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.description}
            </span>
          ) : null}

          <button
            className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
            type="submit"
          >
            {isLoading ? <CircleSpinLoader size="6" bgColor="white" /> : null}{" "}
            <span className="ml-3">Next</span>
          </button>
        </form>
      </div>
    </Container>
  );
}
