export const OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS =
  "open:popup:update_account_success";
export const OPEN_POPUP_EVENT_GET_ACCOUNT_DATA_ERROR =
  "open:popup:update_account_error";
export const CLOSE_POPUP_EVENT = "close:popup";

export const OPEN_POPUP_EVENT_SIGNUP_SUCCESS = "open:popup:sign_up_success";
export const OPEN_POPUP_EVENT_SIGNIN_SUCCESS = "open:popup:sign_in_success";

export const OPEN_POPUP_QRCODE = "open:popup:qrcode";

export const OPEN_POPUP_EVENT_PUBLISH = "open:popup:event_publish";

export const OPEN_POPUP_AUTH_ERROR = "open:popup:auth_error";

export const OPEN_POPUP_DELETE_TYPE_TICKET = "open:popup:delete_type_ticket";
export const OPEN_POPUP_DELETE_USER = "open:popup:delete_user";
export const OPEN_POPUP_BACKEND_ERROR = "open:popup:backend_error";
