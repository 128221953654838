import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Title } from "../../common/titles/Title";
import {
  createStripeAccount,
  getUser,
  updateStripeAccount,
  getStripeAccount,
} from "../../API";
import { useStripe } from "@stripe/react-stripe-js";
import { GroupFormLoader } from "../../common/loaders/GroupFormLoader";

export function StripeConnect() {
  const [user, setUser] = useState({});
  const [stripeAccount, setStripeAccount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [business_type, setBusinessType] = useState("individual");

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const user = await getUser();
        setUser(user);

        if (user?.stripeAccountId) {
          const account = await getStripeAccount();
          setStripeAccount(account);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const stripe = useStripe();

  const createAccount = async () => {
    if (business_type === "individual") {
      const token = await stripe.createToken("account", {
        business_type: "individual",
        individual: {
          first_name: user?.displayName,
          last_name: user?.displayName,
          email: user?.email,
        },
        tos_shown_and_accepted: true,
      });

      if (token?.token.id) {
        const result = await createStripeAccount(
          user,
          token?.token.id,
          undefined
        );
        if (result.success) {
          window.location.href = result.url;
        }
      }
    } else {
      const token = await stripe.createToken("account", {
        business_type: "company",
        company: {
          name: user?.displayName,
        },
        tos_shown_and_accepted: true,
      });
      const personToken = await stripe.createToken("person", {
        person: {
          first_name: user?.displayName,
          last_name: user?.displayName,
        },
      });

      if (token?.token.id && personToken?.token.id) {
        const result = await createStripeAccount(
          user,
          token?.token.id,
          personToken?.token.id
        );
        if (result.success) {
          window.location.href = result.url;
        }
      }
    }
  };

  const updateAccount = async () => {
    const result = await updateStripeAccount(user);
    if (result.success) {
      window.location.href = result.url;
    }
  };

  const onAccountTypeChange = (e) => {
    setBusinessType(e.target.value);
  };

  const getStripeConnectionContent = () => {
    if (
      user?.stripeAccountId === undefined ||
      user?.stripeAccountId === null ||
      user?.stripeAccountId === ""
    ) {
      return (
        <div>
          <p>
            You have not created your stripe account yet. If you want to receive
            the payments via stripe, you need to have the stripe account created
            and verified.
          </p>
          <br />

          <div className="mt-5">
            <label
              htmlFor="accountType"
              className="block font-medium text-white-700"
            >
              Stripe Account Type
            </label>
            <select
              id="accountType"
              name="accountType"
              className={`mt-1 p-4 background border-violet border block w-full rounded-md shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={onAccountTypeChange}
              value={business_type}
            >
              <option value="individual">Individual</option>
              <option value="company">Company</option>
            </select>
          </div>
          <br />
          <button
            className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
            type="button"
            onClick={createAccount}
          >
            <span className="ml-3">Create Account</span>
          </button>
        </div>
      );
    } else if (stripeAccount?.payouts_enabled) {
      <div>
        <p>
          Your account is verified. You can now receive payments through stripe.
        </p>
        <p>
          If you want to update any of information, you can do so by using the
          update account option below. If you update any information which is
          already verified, the verification process will start again for the
          newly provided information. During this period, your account status
          might change.
        </p>
        <button
          className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
          type="button"
          onClick={updateAccount}
        >
          <span className="ml-3">Update Account</span>
        </button>
      </div>;
    } else {
      return (
        <div>
          <p>
            You have successfully created a stripe account. We are verifying the
            information provided by you. If you have provided all the
            information requested by stripe, your account will be ready to
            receive payments after verification. Meanwhile if you want to
            provide more information which stripe might ask for in future, you
            can always update it using the update account button.
          </p>
          <button
            className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
            type="button"
            onClick={updateAccount}
          >
            <span className="ml-3">Update Account</span>
          </button>
        </div>
      );
    }
  };

  return (
    <div className="p-4">
      <div className="flex items-center">
        <Link to="/events">
          <img className="" src="/img/arrow_left.svg" alt="arrow" />
        </Link>
        <div className="flex-auto text-center">
          <Title text="Stripe Account" />
        </div>
      </div>
      {isLoading ? <GroupFormLoader /> : getStripeConnectionContent()}
    </div>
  );
}
