import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";
import { Title } from "../../common/titles/Title";
import { getUser } from "../../API";
import { OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS } from "../custom-events/constants";
import { trigger } from "../custom-events/customEvents";
import { UpdateAccountPopupSuccess } from "../popups/UpdateAccountPopupSuccess";
import { updateUser } from "../../API";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  fullName: Yup.string().required("Full name is required"),
  phone: Yup.string().required("Your phone number is required"),
  email: Yup.string().email("Invalid email").required("Your email is required"),
});

export function ContactInformation() {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUser();
      setUser(user);
    };
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullName: user.displayName || "",
      phone: user.phone || "",
      email: user.email || "",
    },
    enableReinitialize: true,
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await updateUser({
          phone: values.phone,
          email: values.email,
          displayName: values.fullName,
        });

        // firebaseAuth.currentUser.reload();
        trigger(OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS);
      } catch (error) {
        console.error({ error });
      }
      setIsLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <div className="p-4">
      <UpdateAccountPopupSuccess />
      <div className="flex items-center">
        <Link to="/events">
          <img className="" src="/img/arrow_left.svg" alt="arrow" />
        </Link>
        <div className="flex-auto text-center">
          <Title text="Contact Information" />
        </div>
      </div>

      <form className="mt-10" onSubmit={formik.handleSubmit}>
        <label className="block">
          <span className="text-white">Full name</span>
          <input
            id="fullName"
            name="fullName"
            type="fullName"
            className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.fullName ? "red" : "gray"
            }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
            placeholder="Example : John Koffi"
            onChange={formik.handleChange}
            value={formik.values.fullName}
          />
        </label>
        {formik.errors.fullName ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.fullName}
          </span>
        ) : null}

        <label className="block mt-5">
          <span className="text-white">Mobile number</span>
          <input
            id="phone"
            name="phone"
            type="phone"
            className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.phone ? "red" : "gray"
            }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
            placeholder="Example : +233 01 2345 678"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
        </label>
        {formik.errors.phone ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.phone}
          </span>
        ) : null}

        <label className="block mt-5">
          <span className="text-white">Email</span>
          <input
            id="email"
            name="email"
            type="email"
            className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.email ? "red" : "gray"
            }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
            placeholder={`Example : ${formik.values.email || 'your@mail.com'}`}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </label>
        {formik.errors.email ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.email}
          </span>
        ) : null}

        <button
          className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
          type="submit"
        >
          {isLoading ? <CircleSpinLoader size="6" bgColor="white" /> : null}{" "}
          <span className="ml-3">Update</span>
        </button>
      </form>
    </div>
  );
}
