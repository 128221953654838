import React, { useState } from "react";

import { Container } from "../../../common/layouts/Container";
import { Navbar } from "../../../common/navbar/Navbar";
import { Tabs } from "../../../common/tabs/Tabs";
import { useAuth } from "../../../contexts/AuthContext";
import { SignInForm } from "./SignInForm";
import { AuthenticationPopupError } from "../../popups/AuthenticationPopupError";

export function SignIn({ activeTab = 0 }) {
  const { errorAuthMsg } = useAuth();
  const [openTab, setOpenTab] = useState(activeTab);

  const tabs = [
    {
      text: "Sign In",
      component: SignInForm,
    },
  ];

  return (
    <Container classes={["sign-in"]}>
      <Navbar />
      <ul className="flex mb-0 list-none pt-3 pb-4 flex-row" role="tablist">
        {tabs.map((tab, index) => {
          return (
            <li
              key={`${tab.text}-${index}`}
              className={`tabs_text -mb-px last:mr-0 flex-auto text-center`}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(index);
                }}
                data-toggle="tab"
                href={`#link${index}`}
                role="tablist"
              >
                <i className=" fas fa-space-shuttle text-base mr-1" />
                {tab.text}
              </a>
            </li>
          );
        })}
      </ul>
      {tabs.map(({ component: TabComponent, data }, index) => {
        return (
          <div
            key={`tab-component-render-${index}`}
            className={openTab === index ? "block" : "hidden"}
            id={`link${index}`}
          >
            <TabComponent data={data} setOpenTab={setOpenTab} />
          </div>
        );
      })}
      <AuthenticationPopupError message={errorAuthMsg} />
    </Container>
  );
}
