import React, { useEffect, useId, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { CircleSpinLoader } from "../../../common/loaders/CircleSpinLoader";
import { checkPhone } from "../../../API";
import { useAuth } from "../../../contexts/AuthContext";


const DisplayingErrorMessagesSchema = Yup.object().shape({
  phone: Yup.string().required("Your phone number is required"),
});

export const SignInForm = (props) => {
  const { authNumber, setAuthNumber } = useAuth();
  const [valueInput, setValueInput] = useState('')
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneRegistered, setPhoneRegistered] = useState("");
  const id = useId();
  const [input, setInput] = useState(props?.value ?? '');

  const formik = useFormik({
    initialValues: {
      phone: authNumber || "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const phoneIsRegistered = await checkPhone(values.phone);
        if (!phoneIsRegistered) {
          setIsLoading(false);
          setAuthNumber(formik.values.phone);
          return setPhoneRegistered(phoneIsRegistered);
        }
        history.push({
          pathname: "/code-mobile-generated",
          phone: values.phone,
        });
      } catch (error) {
        // console.error("SignInForm", { error });
      }

      setIsLoading(false);
    },
    validateOnChange: true,
    validateOnBlur: false,
  });

  const checkReg = () => {
    if (phoneRegistered === false) {
      return (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          Sorry but this number is not registered
        </span>
      );
    }
  };



  const btnActive = input.length > 0

  console.log(btnActive)

  return (
    <form onSubmit={formik.handleSubmit}>
      <label className="mt-6 block">
        <span className="text-white">Mobile number</span>
        <input
          id="id"
          name="phone"
          type="phone"
          onInput={e => setInput(e.target.value)}
          className={` text-white background-transparent mt-1 p-4 border-violet block w-full rounded-md border-${
            formik.errors.phone ? "red" : "gray"
          }-300 shadow-sm`}
          placeholder="Example : +233 01 2345 678"
          onChange={formik.handleChange}
          value={formik.values.phone}
          pattern="[+ 0-9]+"
        />
      </label>
      {formik.errors.phone ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.phone}
        </span>
      ) : null}

      {checkReg()}

      <div id="recaptcha-container-signin" className="mt-4" />
      <button
        className={`${btnActive ? 'btn-active' : ''} flex justify-center mt-5 background-btn text-white w-full rounded p-2 `}
        type="submit"
      >
        {isLoading ? <CircleSpinLoader size="6" bgColor="white" /> : null}{" "}
        <span className="ml-3">Continue</span>
      </button>
    </form>
  );
};
