import React from "react";
import { Navbar } from "./Navbar";
import { ProfileLinkNav } from "./ProfileLinkNav";
export function NavbarConnected() {
  return (
    <Navbar pathHome="/events">
      <ProfileLinkNav />
    </Navbar>
  );
}
