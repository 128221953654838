import React from "react";
import {
  GoogleMap,
  useLoadScript,
  StandaloneSearchBox,
  Marker,
} from "@react-google-maps/api";
import { getReverseGeocode, getTimeByCoords } from "../../API";
import {useEventContext} from "../../contexts/EventContext";

const key = "AIzaSyABZM7PcqcNkR7fH-8smhfHyL2rq-2vLJs";
const libraries = ["places"];

const Map = ({
  errors,
  onChangeAddress,
  address,
  onChangeGPS,
  gps,
  onChangeCity,
  onChangeCountry,
  onChangeTZ,
}) => {

  const {eventData, locales} = useEventContext()
  const localeId = eventData.localeId

  const selectedLocale =  locales.find(l=>`${l.id}`===localeId)
  const language = selectedLocale?.isoCode ? selectedLocale?.isoCode.substr(0,2) : 'EN'

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: key,
    libraries: libraries,
    language: language
  });

  const getLatLngString = ({ lat, lng }) =>
    `${typeof lat === "function" ? lat() : lat}, ${
      typeof lng === "function" ? lng() : lng
    }`;

  const getLatLngObject = (str) => {
      const [lat, lng] = str.replace(/,/g, "").split(" ");
      const floatLat = parseFloat(lat);
      const floatLng = parseFloat(lng);
      if (!isNaN(floatLat) && !isNaN(floatLng)) {
        return {
          lat: floatLat,
          lng: floatLng,
        };
      }
    return null;
  };

  const center = getLatLngObject(gps) || {
    lat: -3.745,
    lng: -38.523,
  };

  const [map, setMap] = React.useState(null);
  const [searchBox, setSearchBox] = React.useState(null);
  const [, setMarker] = React.useState(null);
  const [position, setPosition] = React.useState(getLatLngObject(gps));

  const onLoadMap = React.useCallback((map) => {
    setMap(map);
  }, []);

  const onLoadSearchBox = React.useCallback((searchBox) => {
    setSearchBox(searchBox);
  }, []);

  const onLoadMarker = React.useCallback((marker) => {
    setMarker(marker);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const onChangeLatLng = (value) => {
    onChangeGPS(value);
    const latLngObj = getLatLngObject(value);
    if (latLngObj) {
      setPosition(latLngObj);
      setAddressFieldByLatLng(latLngObj);
    }
  };

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    if (places.length) {
      const place = places[0];
      onChangeGPS(getLatLngString(place.geometry.location));
      onChangeAddress(place.formatted_address);
      setAnotherData(place);
      setPosition(place.geometry.location);
      map.fitBounds(place.geometry.viewport);
    }
  };

  const getAddressByLatLng = async (latLng) => {
    const latLngString = getLatLngString(latLng);
    if (latLngString) {
      const results = await getReverseGeocode(latLngString, key);
      if (results) {
        return results[0];
      }
    }
    return null;
  };

  const setAnotherData = (address) => {
    if (Array.isArray(address?.address_components)) {
      const country = address.address_components.find((el) =>
        el.types.includes("country")
      );
      const city = address.address_components.find(
        (el) =>
          el.types.includes("locality") || el.types.includes("postal_town")
      );

      onChangeCountry(country?.long_name || "");
      onChangeCity(city?.long_name || "");
    }
    const latLng = getLatLngString(address.geometry.location);
    getTimeByCoords(latLng, key).then((r) => {
      const offset = r?.rawOffset;
      if (!isNaN(offset)) {
        const UTC = offset === 0 ? 0 : offset / 3600;
        onChangeTZ(UTC >= 0 ? `+${UTC}` : UTC.toString());
      }
    });
  };

  const setAddressFieldByLatLng = (latLng) => {
    getAddressByLatLng(latLng).then((address) => {
      if (address) {
        onChangeAddress(address.formatted_address);
        setAnotherData(address);
      }
    });
  };

  const onMapClick = (e) => {
    setPosition(e.latLng);
    const latLngString = getLatLngString(e.latLng);
    onChangeGPS(latLngString);
    setAddressFieldByLatLng(e.latLng);
  };

  const renderMarker = () =>
    position && (
      <Marker
        position={position}
        onLoad={onLoadMarker}
        icon="/img/map-marker.svg"
      />
    );

  const renderContent = () => {
    if (!isLoaded) return null;
    return (
      <>
        <GoogleMap
          id="VenueMap"
          zoom={10}
          onLoad={onLoadMap}
          onUnmount={onUnmount}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
          mapContainerStyle={containerStyle}
          onDblClick={onMapClick}
          center={center}
        >
          {renderMarker()}
        </GoogleMap>
        <label className="block my-3">
          <span className="text-white-700">GPS</span>
          <input
            id="gps"
            name="gps"
            type="text"
            className={`background border-violet borer-violet mt-1 p-4 border block w-full rounded-md border-${
              errors.gps ? "red" : "gray"
            }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
            onChange={(e) => onChangeLatLng(e.target.value)}
            value={gps}
          />
        </label>
        {errors.gps ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {errors.gps}
          </span>
        ) : null}
        <StandaloneSearchBox
          onLoad={onLoadSearchBox}
          onPlacesChanged={onPlacesChanged}
        >
          <>
            <label className="block my-3">
              <span className="text-white-700">Address</span>
              <input

                id="address"
                name="address"
                type="text"
                className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
                  errors.address ? "red" : "gray"
                }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
                onChange={(e) => onChangeAddress(e.target.value)}
                value={address}
                onKeyPress={(e) => { e. key === 'Enter' && e. preventDefault(); }}
              />
            </label>
            {errors.address ? (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.address}
              </span>
            ) : null}
          </>
        </StandaloneSearchBox>
      </>
    );
  };

  return renderContent();
};

export default React.memo(Map);
