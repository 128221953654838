import { Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { logOut } from "../../features/authentication/common/authHelper";
import { getUser } from "../../API";

export function ProfileLinkNav() {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUser();
      setUser(user);
    };
    fetchData();
  }, []);

  const itIsAdmin = () => {
    if (user?.accessLevel === "Owner") {
      return (
        <Menu.Item>
          {({ active }) => (
            <Link
              to="/profile/admin-panel"
              className={`${
                active ? "bg-violet text-white font-bold" : "text-white"
              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
            >
              Admin panel
            </Link>
          )}
        </Menu.Item>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div className="ml-3 relative">
      <Menu as="div" className="relative inline-block text-left z-50">
        <div>
          <Menu.Button
            className="flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            id="user-menu-button"
            aria-expanded="false"
            aria-haspopup="true"
          >
            <span className="sr-only">Open user menu</span>
            <img className="h-10" src="/img/test-img-user.svg" alt="" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="background origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile/contact-information"
                  className={`${
                    active ? "bg-violet text-white font-bold" : "text-white"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Contact Information
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile/bank-account-information"
                  className={`${
                    active ? "bg-violet text-white font-bold" : "text-white"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Bank Account Information
                </Link>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile/mobile-money-information"
                  className={`${
                    active ? "bg-violet text-white font-bold" : "text-white"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Mobile Money Information
                </Link>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile/stripe-connect"
                  className={`${
                    active ? "bg-violet text-white font-bold" : "text-white"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Stripe Account
                </Link>
              )}
            </Menu.Item>

            {itIsAdmin()}

            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={logOut}
                  className={`${
                    active ? "bg-violet text-white font-bold" : "text-gray-400"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Sign out
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
