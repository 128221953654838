import React from "react";
import { useParams } from "react-router-dom";

import { HeaderDetails } from "../../common/header/HeaderDetails";
import { Container } from "../../common/layouts/Container";
import { UpdateAccountPopupSuccess } from "../popups/UpdateAccountPopupSuccess";
import { UpdateTicketForm } from "./UpdateTicketForm";

export function EventTicketUpdate() {
  const { id } = useParams();
  return (
    <Container>
      <UpdateAccountPopupSuccess />
      <HeaderDetails
        pathReturn={`/events/${id}/tickets/info`}
        textTitle="Update Ticket"
      />
      <UpdateTicketForm />
    </Container>
  );
}
