import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { off, on, trigger } from "../custom-events/customEvents";
import { OPEN_POPUP_DELETE_USER, OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS } from "../custom-events/constants";
import { useHistory, useParams } from "react-router-dom";
import { useEventContext } from "../../contexts/EventContext";
import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";
import { deleteTicket, getUsers, updateAnotherUser } from "../../API";
import { useFormik } from "formik";

export function DeleteUsersPopup() {
  let [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { typeTicketSelected } = useEventContext();
  const history = useHistory();

  function closeModal() {
    setIsOpen(false);
    trigger("close:popup");
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    on(OPEN_POPUP_DELETE_USER, openModal);

    return () => {
      off(OPEN_POPUP_DELETE_USER, openModal);
    };
  });

  const params = useParams();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const users = await getUsers();
      setUsers(users);
    };
    fetchData();
  }, []);

  const user = users.find((e) => e.id === Number(params.userId));


  const formik = useFormik({
    initialValues: {
      accessLevel: user?.accessLevel,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await updateAnotherUser({
          accessLevel: "Inactive",
          id: params.userId,
        });
        history.push(`/profile/admin-panel`)
      } catch (error) {
      }
      setIsLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        open={isOpen}
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block  background w-full max-w-md p-6 my-8 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="mt-2 flex flex-col justify-center items-center">
                <p className="mb-4 text-white">Are you sure you want to disable this user? </p>
              </div>

              <div className="flex justify-center">
                <form onSubmit={formik.handleSubmit}>
                <button
                  className="flex justify-center mt-5 bg-violet hover:bg-red-500 text-white rounded px-5 py-2 mr-4"
                  onChange={formik.handleChange}
                  value={formik.values.accessLevel}
                >
                  {isLoading ? (
                    <CircleSpinLoader size="6" bgColor="white" />
                  ) : null}{" "}
                  <span className="">Yes</span>
                </button>
                </form>

                <button
                  className="flex justify-center mt-5 border-violet background text-white rounded px-5 py-2"
                  onClick={closeModal}
                >
                  No
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
