import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { trigger } from "../custom-events/customEvents";
import { OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS } from "../custom-events/constants";
import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";
import { updateBankAccount } from "../../API";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  bankName: Yup.string().required("Bank name is required"),
  accountNumber: Yup.string().required("Account number is required"),
  branchName: Yup.string().required("Branch name is required"),
  swiftCode: Yup.string().required("Swift code is required"),
  country: Yup.string().required("Country is required"),
});

export function BankAccountInformationForm({ data = {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      bankName: data?.bankName,
      accountNumber: data.accountNumber,
      branchName: data.branchName || "",
      swiftCode: data.swiftCode || "",
      country: data.country || "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await updateBankAccount({
          bankName: values.bankName,
          accountNumber: values.accountNumber,
          branchName: values.branchName,
          swiftCode: values.swiftCode,
          country: values.country,
        });

        trigger(OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <form className="mt-10" onSubmit={formik.handleSubmit}>
      <label className="block">
        <span className="text-white-700">Bank Name</span>
        <input
          id="bankName"
          name="bankName"
          type="text"
          className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.bankName ? "red" : "gray"
          }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
          placeholder="Example: Ecobank Ghana"
          onChange={formik.handleChange}
          value={formik.values.bankName}
        />
      </label>
      {formik.errors.bankName ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.bankName}
        </span>
      ) : null}

      <label className="block mt-5">
        <span className="text-white-700">Account Number</span>
        <input
          id="accountNumber"
          name="accountNumber"
          type="text"
          className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.accountNumber ? "red" : "gray"
          }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
          placeholder="Example: GB29 NWBK 6016 1331 9268 19"
          onChange={formik.handleChange}
          value={formik.values.accountNumber}
        />
      </label>
      {formik.errors.accountNumber ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.accountNumber}
        </span>
      ) : null}

      <label className="block mt-5">
        <span className="text-white-700">Branch Name</span>
        <input
          id="branchName"
          name="branchName"
          type="text"
          className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.bankName ? "red" : "gray"
          }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
          placeholder="Example : 30456"
          onChange={formik.handleChange}
          value={formik.values.branchName}
        />
      </label>
      {formik.errors.branchName ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.branchName}
        </span>
      ) : null}

      <label className="block mt-5">
        <span className="text-white-700">Swift Code</span>
        <input
          id="swiftCode"
          name="swiftCode"
          type="text"
          className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.swiftCode ? "red" : "gray"
          }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
          placeholder="Example : CHASUS33"
          onChange={formik.handleChange}
          value={formik.values.swiftCode}
        />
      </label>
      {formik.errors.swiftCode ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.swiftCode}
        </span>
      ) : null}

      <label className="block mt-5">
        <span className="text-white-700">Country</span>
        <input
          id="country"
          name="country"
          type="text"
          className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.country ? "red" : "gray"
          }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
          placeholder="Example : Ghana"
          onChange={formik.handleChange}
          value={formik.values.country}
        />
      </label>
      {formik.errors.country ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.country}
        </span>
      ) : null}

      <button
        className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
        type="submit"
      >
        {isLoading ? <CircleSpinLoader size="6" bgColor="white" /> : null}{" "}
        <span className="ml-3">Update</span>
      </button>
    </form>
  );
}
