import React, {useEffect, useState} from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

import { trigger } from "../custom-events/customEvents";
import { OPEN_POPUP_EVENT_PUBLISH } from "../custom-events/constants";
import { EventPopupPublishSuccess } from "../popups/EventPopupPublishSuccess";
import { useEventContext } from "../../contexts/EventContext";
import {getDashboardSales, publishEvent} from "../../API";
import Sales from "../dashboards/sales/Sales";
import Tickets from "../dashboards/tickets/Tickets";
import Scanned from "../dashboards/scanned/Scanned";

export function EventDashboard({ data = {} }) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [sales, setSales] = useState([]);
  const [currency, setCurrency] = useState(null);
  const [stateEvent, setStateEvent] = useState("");
  const { saveLocalEventData, currencies } = useEventContext();
  const soldTickets = data?.soldTickets;
  const eventBalance = data?.eventBalance || "0";
  const scannedTickets = data?.scannedTickets || "0";
  const { id } = useParams();
  const [currentCurrency, setCurrentCurrency] = useState(currencies[0]);

  const fetchData = async (method) => {
    setIsLoading(true);
    try {
      const result = await method();
      setSales(result);
    } finally {
      setIsLoading(false);
    }
  };

  let period = 'year'

  useEffect(() => {
    fetchData(() => {
      getDashboardSales(id, period).then((currency) => {
        setCurrency(currency);
        Object.keys(currency).map((c) => setCurrentCurrency(c));
      });
    });
  }, [id, period]);




  const renderCurrency = () => {
    if (!currencies) {
      return null;
    }

    return currencies.map((c) => <option value={c.name} key={c.id} >{c.name}</option>);
  };

  const formik = useFormik({
    initialValues: {
      publication:
        data.published || (data.event && data.event.published)
          ? "publish"
          : "draft",
    },
    validate: (values) => {
      const errors = {};
      if (values.publication === "") {
        errors.publication = "No value for publishing";
      }

      return errors;
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      if (values.publication === null) return;

      try {
        await publishEvent(data.eventId, {
          eventData: {
            published: values.publication === "publish",
          },
        });

        setStateEvent(
          values.publication === "publish" ? "published" : "drafted"
        );
        saveLocalEventData({
          event: {
            ...data.event,
            published: values.publication === "publish",
          },
        });
        trigger(OPEN_POPUP_EVENT_PUBLISH);
      } catch (error) {
        console.log("EventDashboard", { error });
      }
      setIsLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onSelectChange = (val) => {
    formik.handleChange(val);
    formik.handleSubmit();
  };

  return (
    <>
      <div className="mx-1">
        <EventPopupPublishSuccess state={stateEvent} />
        <h2 className="font-bold text-2xl flex justify-between">
          <span className='pt-6'>Dashboard</span>
          <form
            className="flex justify-between items-center"
            onSubmit={formik.handleSubmit}
          >
            <select
              id="publication"
              name="publication"
              autoComplete="publication"
              className="border text-white border-violet shadow-sm h-8 bg-violet text-xs font-bold rounded outline-none "
              onChange={onSelectChange}
              value={formik.values.publication}
            >
              <option value="draft">Draft</option>
              <option value="publish">Publish</option>
            </select>
          </form>
        </h2>
        <ul className="flex mt-6">
          <li className="border rounded-md border-white px-3 py-1 flex flex-col justify-center items-center mr-4 text-center">
            <div className="text-sm">Event balance</div>
            <div className="leading-5 font-bold flex">
              <div className="flex items-center">
                <img className="mr-1 h-3.5" src="/img/coin.svg" alt=""/>
                {eventBalance}
              </div>
              </div>
          </li>
          <li className="border rounded-md border-white  px-3 py-1  flex flex-col justify-center items-center mr-4 text-center">
            <div className="text-sm">Sold tickets</div>
            <div className="leading-5 font-bold">{soldTickets}</div>
          </li>
          <li className="border rounded-md border-white  px-3 py-1 flex flex-col justify-center items-center text-center">
            <div className="text-sm">Scanned tickets</div>
            <div className="leading-5 font-bold">{scannedTickets}</div>
          </li>
        </ul>

        <div className="max-w-4xl mx-auto h-auto pt-3">
          <div className="text-sm pb-1.5">currency</div>

          <select
            id="currency"
            name="currency"
            autoComplete="currency"
            className={` border-violet background text-sm flex justify-between w-full border rounded-md focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
            onChange={(val) => setCurrentCurrency(val.target.value)}
          >
            {renderCurrency()}
          </select>

        </div>

        <div className="max-w-4xl mx-auto">
          {/*sales*/}
          <Sales eventId={id} currentCurrency={currentCurrency} />
          {/*tickets*/}
          <Tickets eventId={id} />
          {/*scanned*/}
          <Scanned eventId={id} />
        </div>
      </div>
    </>
  );
}
