import React from "react";
import { useParams } from "react-router-dom";

import { HeaderDetails } from "../../common/header/HeaderDetails";
import { Container } from "../../common/layouts/Container";
import { Tabs } from "../../common/tabs/Tabs";
import { useEventContext } from "../../contexts/EventContext";
import { UpdateAccountPopupSuccess } from "../popups/UpdateAccountPopupSuccess";
import { EventUpdateBasicDetails } from "./EventUpdateBasicDetails";
import { EventUpdateVenue } from "./EventUpdateVenue";
import { ListInfoLoader } from "./ListInfoLoader";

export function EventUpdate() {
  const { id } = useParams();
  const { eventData, getEventDataById } = useEventContext();
  const path = `/events/details/${id}?tab=1`;
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getEventDataById(id).finally(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const tabs = [
    {
      text: "Basic Details",
      component: EventUpdateBasicDetails,
      data: eventData,
    },
    {
      text: "Venue",
      component: EventUpdateVenue,
      data: eventData,
    },
  ];

  const renderContent = () => {
    if (isLoading) return <ListInfoLoader />;

    return <Tabs tabs={tabs} />;
  };

  return (
    <Container className="bg-white">
      <HeaderDetails pathReturn={path} textTitle="Update Event" />
      <UpdateAccountPopupSuccess />
      {renderContent()}
    </Container>
  );
}
