import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { HeaderDetails } from "../../common/header/HeaderDetails";
import { timeSince } from "../../common/helper/timeSince";
import { Container } from "../../common/layouts/Container";
import { useEventContext } from "../../contexts/EventContext";
import { OPEN_POPUP_DELETE_TYPE_TICKET } from "../custom-events/constants";
import { trigger } from "../custom-events/customEvents";
import { DeleteTypeTicketPopup } from "../popups/DeleteTypeTicketPopup";

export function EventTicketInfo() {
  const history = useHistory();
  const { id } = useParams();
  const { typeTicketSelected } = useEventContext();

  const timeAgo = Date.now() - typeTicketSelected.createdAtTimestamp;
  const handleClickOnEditButton = () => {
    history.push(`/events/${id}/ticket/update`);
  };

  const handleClickOnDeleteButton = () => {
    trigger(OPEN_POPUP_DELETE_TYPE_TICKET);
  };

  return (
    <Container>
      <DeleteTypeTicketPopup />
      <HeaderDetails
        pathReturn={`/events/details/${id}?tab=2`}
        textTitle="Ticket Info"
      />
      <div className="p-6">
        <h2 className="mx-6 mt-5 font-bold text-xl flex justify-between items-center">
          <span className="font-bold">{typeTicketSelected.type}</span>
          <div>
            <button type="button" onClick={handleClickOnEditButton}>
              <img src="/img/edit_button.svg" alt="edit icon" />
            </button>
            <button
              className="pl-2"
              type="button"
              onClick={handleClickOnDeleteButton}
            >
              <img src="/img/delete_button.svg" alt="edit icon" />
            </button>
          </div>
        </h2>
      </div>
      <div className="p-6">
        <h4 className="text-white-400 text-lg">Basic Details</h4>
        <p className="mt-4">
          <span className="font-bold capitalize">quantity :</span>
          {typeTicketSelected.quantity}
        </p>
        <p className="mt-4">
          <span className="font-bold capitalize">price :</span>
          {typeTicketSelected.price}
        </p>
        <p className="mt-4">
          <span className="font-bold capitalize">max Reg :</span>
          {typeTicketSelected.max}
        </p>
        <p className="mt-4">
          <span className="font-bold capitalize">sold :</span>
          {typeTicketSelected.soldTickets || 0}
        </p>
        <h4 className="text-white-400 text-lg mt-4">Description Ticket</h4>
        <p className="mt-4">{typeTicketSelected.description}</p>
        <h4 className="mt-8 text-gray-400 text-lg">
          Created {timeSince(new Date(Date.now() - timeAgo))} ago
        </h4>
      </div>
    </Container>
  );
}
