import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { LANDING_URL } from "../../config/env";

export function Logo(props) {
  return (
    <Link
      className={`mx-auto block font-bold ${props.bgColor} border-0 rounded`}
      to={props.path || ""}
    >
      <span className="sr-only">Kawris</span>

      <div className="flex flex-row justify-between space-x-2 items-center">
        <img src="/img/logo.svg" alt="kawris Logo" title="kawris Logo" />
      </div>
    </Link>
  );
}

Logo.propTypes = {
  bgColor: PropTypes.string,
  path: PropTypes.string,
};
