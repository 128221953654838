import React from "react";

import { OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS } from "../custom-events/constants";
import { WrapperPopup } from "./WrapperPopup";

export function UpdateAccountPopupSuccess({ text, isSuccess = true }) {
  return (
    <WrapperPopup openEventName={OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS}>
      Your data has been saved successfully
    </WrapperPopup>
  );
}
