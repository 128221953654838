import React from "react";

import { OPEN_POPUP_EVENT_PUBLISH } from "../custom-events/constants";
import { WrapperPopup } from "./WrapperPopup";

export function EventPopupPublishSuccess({ state }) {
  return (
    <WrapperPopup openEventName={OPEN_POPUP_EVENT_PUBLISH}>
      Event has been <span className="font-bold">{state}</span> with success.
    </WrapperPopup>
  );
}
