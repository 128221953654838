import React, { useContext, useEffect, useState } from "react";
import { getCurrency, getEventWithTypeTickets, getLocales, getCountries } from "../API";
import { trigger } from "../features/custom-events/customEvents";
import { OPEN_POPUP_EVENT_GET_ACCOUNT_DATA_ERROR } from "../features/custom-events/constants";

const EventContext = React.createContext();

export function useEventContext() {
  return useContext(EventContext);
}

export function EventProvider({ children }) {
  const [eventData, setEventData] = useState({});
  const [typeTickets, setTypeTickets] = useState([]);
  const [typeTicketSelected, setTypeTicketSelected] = useState({});
  const [filterEventsSelected, setFilterEventsSelected] = useState("all");
  const [locales, setLocales] = useState([]);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const fetchLocales = async () => {
    const data = await getLocales();
    setLocales(data);
  };

  const fetchCountries = async () => {
    const data = await getCountries();
    setCountries(data?.result);
  };

  useEffect(() => {
    fetchLocales();
    fetchCountries();
  }, []);

  const fetchCurrencies = async (countryName) => {
    const data = await getCurrency(countryName);
    setCurrencies(data);
  };

  useEffect(() => {
    fetchCurrencies(eventData?.country)
  }, [eventData?.country])

  function saveLocalEventData(values) {
    setEventData((prevState) => ({
      ...prevState,
      ...values,
      event: {
        ...values.event,
      },
    }));
  }

  function resetLocalEventData() {
    setEventData({});
  }

  function listTickets() {
    return typeTickets;
  }

  function getOneTicket(id) {
    return typeTickets.find((typeTicket) => typeTicket.id === id);
  }

  function saveLocalTypeTicketSelected(typeTicket) {
    setTypeTicketSelected(typeTicket);
  }

  async function getEventDataById(id) {
    try {
      const data = await getEventWithTypeTickets(id);
      setEventData(data?.result?.event);
      setTypeTickets(data?.result?.typeTickets);
    } catch (error) {
      trigger(OPEN_POPUP_EVENT_GET_ACCOUNT_DATA_ERROR);
    }
  }

  const value = {
    locales,
    countries,
    eventData,
    currencies,
    country,
    fetchCurrencies,
    typeTickets,
    currentEventId: eventData.id,
    saveLocalEventData,
    getEventDataById,
    listTickets,
    getOneTicket,
    saveLocalTypeTicketSelected,
    typeTicketSelected,
    resetLocalEventData,
    filterEventsSelected,
    setFilterEventsSelected,
  };

  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
}
