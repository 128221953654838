import React, { Fragment, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { compareDesc } from "date-fns";

import { OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS } from "../custom-events/constants";
import { trigger } from "../custom-events/customEvents";
import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";
import { useParams, useHistory } from "react-router-dom";
import { deleteEvent, updateEvent } from "../../API";
import { useEventContext } from "../../contexts/EventContext";
import { Dialog, Transition } from "@headlessui/react";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  name: Yup.string().required("The name of event is required"),
  localeId: Yup.string().required("The language is required"),
  category: Yup.string().required("The category is required"),
  startDate: Yup.string().required("Start date is required"),
  startTime: Yup.string().required("Start time is Required"),
  endDate: Yup.string().required("End date is required"),
  endTime: Yup.string()
    .required("end time cannot be empty")
    .test(
      "is-greater",
      "The end date should be after the start date",
      function () {
        const { startTime, endTime, startDate, endDate } = this.parent;

        if (!startTime || !endTime || !startDate || !endDate) {
          return false;
        }

        const result = compareDesc(
          new Date(
            `${startDate.replace(/\./g, "/").replace(/-/g, "/")} ${startTime}`
          ),
          new Date(
            `${endDate.replace(/\./g, "/").replace(/-/g, "/")} ${endTime}`
          )
        );

        return result === 1;
      }
    ),
  description: Yup.string()
    .required("Description is required")
    .max(2000, "Max number of characters - 2000."),
});

export function EventUpdateBasicDetails({ data = {} }) {
  const { id } = useParams();
  const history = useHistory();
  const { locales } = useEventContext();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      localeId: data.localeId || "",
      category: data.category || "",
      startDate: data.startDate || "",
      startTime: data.startTime || "",
      endDate: data.endDate || "",
      endTime: data.endTime || "",
      description: data.description || "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await updateEvent(id, {
          eventData: {
            ...values,
          },
        });

        trigger(OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS);
      } catch (error) {
      }
      setIsLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const renderLocaleOptions = () =>
    locales.map((l) => (
      <option value={l.id} key={l.id}>
        {l.name}
      </option>
    ));

  const fetchDelete = async () => {
    setDeleteModalOpen(false);
    await deleteEvent(id);
    history.replace("/events");
  };

  const renderDeleteModal = () => {
    return (
      <Transition show={deleteModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block background  w-full max-w-md p-6 my-8 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="mt-2 flex flex-col justify-center items-center">
                  <p className="mb-8  text-2xl font-bold text-white">Delete event?</p>
                </div>

                <div className="flex justify-center">
                  <button
                    className="flex justify-center mt-5 bg-violet hover:bg-red-500 text-white rounded px-5 py-2 mr-4"
                    onClick={fetchDelete}
                  >
                    <span>Delete</span>
                  </button>

                  <button
                    className="border-violet flex justify-center mt-5 background hover:bg-violet text-white rounded px-5 py-2"
                    onClick={() => setDeleteModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <>
      <form className="mt-5" onSubmit={formik.handleSubmit}>
        <label className="block">
          <span className="text-white-700">Name</span>
          <input
            id="name"
            name="name"
            type="text"
            className={`mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.name ? "red" : "gray"
            }-300 shadow-sm border-violet background focus:border-violet focus:ring-purple-400 focus:ring 0 focus:ring-opacity-50`}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
        </label>
        {formik.errors.name ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.name}
          </span>
        ) : null}
        <div className="mt-5">
          <label htmlFor="language" className="block font-medium text-white-700">
            Language
          </label>
          <select
            id="localeId"
            name="localeId"
            autoComplete="localeId"
            className={`mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.localeId ? "red" : "gray"
            }-300 shadow-sm border-violet background focus:border-violet focus:ring-purple-400 focus:ring focus:ring-opacity-50`}
            onChange={formik.handleChange}
            value={formik.values.localeId}
          >
            <option value="">Choose language</option>
            {renderLocaleOptions()}
          </select>
        </div>
        {formik.errors.localeId ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.localeId}
          </span>
        ) : null}

        <div className="mt-5">
          <label htmlFor="category" className="block font-medium text-white-700">
            Category
          </label>
          <select
            id="category"
            name="category"
            autoComplete="category"
            className={`mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.category ? "red" : "gray"
            }-300 shadow-sm border-violet background focus:border-violet focus:ring-purple-400 focus:ring focus:ring-opacity-50`}
            onChange={formik.handleChange}
            value={formik.values.category}
          >
            <option value="">Choose category</option>
            <optgroup label="Corporate events">
              <option value="seminar">Seminar</option>
              <option value="conference">Conference</option>
              <option value="trade show">Trade show</option>
            </optgroup>
            <optgroup label="Social events">
              <option value="themed party">Themed party</option>
              <option value="interactive performance">
                Interactive performance
              </option>
              <option value="summit">Summit</option>
            </optgroup>
            <optgroup label="Fundraising event">
              <option value="sponsored sporting event">
                Sponsored sporting event
              </option>
              <option value="gala dinner">Gala dinner</option>
            </optgroup>
            <optgroup label="Festivals">
              <option value="music festival">Music festival</option>
              <option value="food festival">Food festival</option>
            </optgroup>
            <optgroup label="Community events">
              <option value="street party">Street party</option>
              <option value="pop up event">Pop up event</option>
            </optgroup>
          </select>
        </div>
        {formik.errors.category ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.category}
          </span>
        ) : null}

        <label className="block mt-5">
          <span className="text-white-700">Start date</span>
          <input
            id="startDate"
            name="startDate"
            type="date"
            className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.startDate ? "red" : "gray"
            }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
            onChange={formik.handleChange}
            value={formik.values.startDate}
          />
        </label>
        {formik.errors.startDate ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.startDate}
          </span>
        ) : null}

        <label className="block mt-5">
          <span className="text-white-700">Start time</span>
          <input
            id="startTime"
            name="startTime"
            type="time"
            className={`mt-1 p-4 border block w-full rounded-md background border-violet border-${
              formik.errors.startTime ? "red" : "gray"
            }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
            onChange={formik.handleChange}
            value={formik.values.startTime}
          />
        </label>
        {formik.errors.startTime ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.startTime}
          </span>
        ) : null}

        <label className="block mt-5">
          <span className="text-white-700">End date</span>
          <input
            id="endDate"
            name="endDate"
            type="date"
            className={`mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.endDate ? "red" : "gray"
            }-300 shadow-sm border-violet background focus:border-violet focus:ring-purple-400 focus:ring focus:ring-opacity-50`}
            onChange={formik.handleChange}
            value={formik.values.endDate}
          />
        </label>
        {formik.errors.endDate ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.endDate}
          </span>
        ) : null}

        <label className="block mt-5">
          <span className="text-white-700">End time</span>
          <input
            id="endTime"
            name="endTime"
            type="time"
            className={`mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.endTime ? "red" : "gray"
            }-300 shadow-sm border-violet background focus:border-violet focus:ring-purple-400 focus:ring focus:ring-opacity-50`}
            onChange={formik.handleChange}
            value={formik.values.endTime}
          />
        </label>
        {formik.errors.endTime ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.endTime}
          </span>
        ) : null}

        <div className="mt-5">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-white-700"
          >
            Description
          </label>
          <div className="mt-1">
            <textarea
              id="description"
              name="description"
              rows="3"
              className={`shadow-sm focus:ring-indigo-500 border-violet background focus:border-violet focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-${
                formik.errors.description ? "red" : "gray"
              }-300 rounded-md`}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>
          <p className="mt-2 text-sm text-white-500">
            Brief description for your event. Max number of characters - 2000.
          </p>
        </div>
        {formik.errors.description ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.description}
          </span>
        ) : null}

        <button
          className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
          type="submit"
        >
          {isLoading ? <CircleSpinLoader size="6" bgColor="white" /> : null}{" "}
          <span className="ml-3">Update Event</span>
        </button>
        <button
          onClick={() => setDeleteModalOpen(true)}
          type="button"
          className="flex justify-center mt-5 background  text-violet w-full rounded p-2 border border-violet"
        >
          <span className="ml-3">Delete Event</span>
        </button>
      </form>
      {renderDeleteModal()}
    </>
  );
}
