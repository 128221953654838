import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { HeaderDetails } from "../../common/header/HeaderDetails";
import { Container } from "../../common/layouts/Container";
import { Tabs } from "../../common/tabs/Tabs";
import { QRCodePopup } from "../popups/QRCodePopup";
import { AttendeesDetails } from "./AttendeesDetails";
import { EventDashboard } from "./EventDashboard";
import { ListInfoLoader } from "./ListInfoLoader";
import { EventInfo } from "./EventInfo";
import { TypeTicketsDetails } from "./TypeTicketsDetails";
import { useEventContext } from "../../contexts/EventContext";

export function EventDetails() {
  const { getEventDataById, eventData, typeTickets } = useEventContext();
  const [activeTab, setActiveTab] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { id } = useParams();

  const tabProps = {
    eventId: id,
  };


  useEffect(() => {
    setIsLoading(true);
    getEventDataById(id).finally(() => {
      setIsLoading(false);
    });

    const searchParams = new URLSearchParams(location.search);
    setActiveTab(+searchParams.get("tab"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location]);

  const tabs = [
    {
      text: "DASHBOARD",
      component: EventDashboard,
      data: { ...tabProps, ...eventData, ...typeTickets },
    },
    {
      text: "INFO",
      component: EventInfo,
      data: { ...tabProps, ...eventData },
    },
    {
      text: "TICKETS",
      component: TypeTicketsDetails,
      data: {
        ...tabProps,
        typeTickets: typeTickets,
        event: eventData,
      },
    },
    {
      text: "ATTENDEES",
      component: AttendeesDetails,
      data: { ...tabProps, event: eventData },
    },
  ];
  return (
    <Container withDecoration={true}>
      <QRCodePopup eventId={eventData.id} />
      <HeaderDetails
        pathReturn="/events"
        textTitle={`${eventData?.name || "Loading..."}`}
        extraRightButton="share"
      />
      {!isLoading ? (
        <Tabs tabs={tabs} activeTab={activeTab} />
      ) : (
        <ListInfoLoader />
      )}
    </Container>
  );
}
