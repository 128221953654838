import React from "react";
import { SearchIcon } from "@heroicons/react/solid";

import { useEventContext } from "../../contexts/EventContext";

export function EventHeader({ itemActive, selectFilter }) {
  const { setFilterEventsSelected } = useEventContext();

  const selectItemHeader = (selectedItem) => {
    setFilterEventsSelected(selectedItem);
  };

  return (
    <div className="flex justify-between mx-6">
      <ul className="text-white flex items-start">
        <li
          onClick={() => selectItemHeader("all")}
          className={`flex flex-col justify-center items-center mr-4 ${
            itemActive === "all" ? "font-bold" : "font-normal"
          }`}
        >
          <span className="cursor-pointer">All</span>
          {itemActive === "all" ? (
            <span className="text-4xl leading-3">.</span>
          ) : null}
        </li>
        <li
          onClick={() => selectItemHeader("published")}
          className={`flex flex-col justify-center items-center mr-4 ${
            itemActive === "published" ? "font-bold" : "font-normal"
          }`}
        >
          <span className="cursor-pointer">Published</span>
          {itemActive === "published" ? (
            <span className="text-4xl leading-3">.</span>
          ) : null}
        </li>
        <li
          onClick={() => selectItemHeader("drafted")}
          className={`flex flex-col justify-center items-center mr-4 ${
            itemActive === "drafted" ? "font-bold" : "font-normal"
          }`}
        >
          <span className="cursor-pointer">Draft</span>
          {itemActive === "drafted" ? (
            <span className="text-4xl leading-3">.</span>
          ) : null}
        </li>
        <li
          onClick={() => selectItemHeader("past")}
          className={`flex flex-col justify-center items-center mr-4 ${
            itemActive === "past" ? "font-bold" : "font-normal"
          }`}
        >
          <span className="cursor-pointer">Past</span>
          {itemActive === "past" ? (
            <span className="text-4xl leading-3">.</span>
          ) : null}
        </li>
      </ul>
      <div>
        <img src="/img/icon_search.svg" className="w-6 h-6" />
      </div>
    </div>
  );
}
