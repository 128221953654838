import React from "react";

export function StepsCreationEvent({
  step = 1,
  isFirstStepFinished = false,
  isSecondStepFinished = false,
}) {
  return (
    <div className="mt-4 flex justify-between px-6">
      <div className="text-center flex flex-col justify-between items-center">
        <span className={`${step === 1 ? " font-bold" : "text-white-400"}`}>
          O1
        </span>
        <h3
          className={`my-2 text-lg ${
            step === 1 ? "font-bold text-white-700" : "text-white-400"
          }`}
        >
          Basic details
        </h3>
        {isFirstStepFinished ? (
          <img
            className="h-2 w-2"
            src="/img/icon_next_step.svg"
            alt="icon step"
          />
        ) : (
          <img
            className="h-4 w-4"
            src={`/img/${
              step === 1 ? "icon_active_step" : "icon_next_step"
            }.svg`}
            alt="icon step"
          />
        )}
      </div>
      <div className="text-center flex flex-col justify-between items-center">
        <span className={`${step === 2 ? " font-bold" : "text-white-400"}`}>
          O2
        </span>
        <h3
          className={`my-2 text-lg ${
            step === 2 ? "font-bold text-white-700" : "text-white-400"
          }`}
        >
          Venue
        </h3>
        {isSecondStepFinished ? (
          <img
            className="h-2 w-2"
            src="/img/icon_next_step.svg"
            alt="icon step"
          />
        ) : (
          <img
            className="h-4 w-4"
            src={`/img/${
              step === 2 ? "icon_active_step" : "icon_next_step"
            }.svg`}
            alt="icon step"
          />
        )}
      </div>
      <div className="text-center flex flex-col justify-between items-center">
        <span className={`${step === 3 ? " font-bold" : "text-white-400"}`}>
          O3
        </span>
        <h3
          className={`my-2 text-lg ${
            step === 3 ? "font-bold text-white-700" : "text-white-400"
          }`}
        >
          Tickets
        </h3>
        {
          <img
            className="h-4 w-4"
            src={`/img/${
              step === 3 ? "icon_active_step" : "icon_next_step"
            }.svg`}
            alt="icon step"
          />
        }
      </div>
    </div>
  );
}
