import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { OPEN_POPUP_QRCODE } from "../../features/custom-events/constants";
import { trigger } from "../../features/custom-events/customEvents";
import { Title } from "../titles/Title";

export function HeaderDetails({ pathReturn, textTitle, extraRightButton }) {
  const handleClickShareButton = () => {
    trigger(OPEN_POPUP_QRCODE);
  };

  return (
    <div className="flex items-center justify-between mx-6 max-w-full">
      <Link to={pathReturn} className="w-3.5 flex-shrink-0">
        <img src="/img/arrow_left.svg" alt="arrow" />
      </Link>
      <div className="text-center w-10/12">
        <Title
          text={textTitle}
          extraStyles="overflow-hidden overflow-ellipsis whitespace-nowrap"
          size="xl"
        />
      </div>
      {extraRightButton === "share" ? (
        <button type="button" onClick={handleClickShareButton}>
          <img
            src="/img/share.svg"
            alt="icon share"
            className="w-4.5 flex-shrink-0"
          />
        </button>
      ) : null}
    </div>
  );
}

HeaderDetails.propTypes = {
  pathReturn: PropTypes.string,
  textTitle: PropTypes.string,
  extraRightButton: PropTypes.string,
};
