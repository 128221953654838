import React from "react";

export function CircleSpinLoader({ size, bgColor }) {
  return (
    <div className="flex justify-center items-center">
      <div
    className={`animate-spin rounded-full h-${size} w-${size} border-b-2 border-${bgColor}`}
    />
    </div>
  );
}
