import React from "react";

export function FormLoader() {
  return (
    <div className="mt-8 border border-violet shadow rounded-md p-4 max-w-sm w-full mx-auto">
      <div className="animate-pulse flex space-x-4 h-full items-center justify-center">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-violet rounded w-3/4"> </div>

          <div className="space-y-2">
            <div className="h-4 bg-violet rounded"> </div>
            <div className="h-4 bg-violet rounded w-5/6"> </div>
          </div>

          <div className="space-y-2">
            <div className="h-4 bg-violet rounded"> </div>
            <div className="h-4 bg-violet rounded w-5/6"> </div>
            <div className="h-4 bg-violet rounded"> </div>
            <div className="h-4 bg-violet rounded w-5/6"> </div>
          </div>
        </div>
      </div>
    </div>
  );
}
