import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { SignIn } from "../features/authentication/signin/SignIn";
import { HomeEvent } from "../features/events/HomeEvent";
import { AuthProvider } from "../contexts/AuthContext";
import "./App.css";
import { ContactInformation } from "../features/profile/ContactInformation";
import { BankAccountInformation } from "../features/profile/BankAccountInformation";
import { MobileMoneyInformation } from "../features/profile/MobileMoneyInformation";
import { AdminPanel } from "../features/admin-panel/AdminPanel";
import { UserInfo } from "../features/admin-panel/UserInfo";
import { UpdateUserInfo } from "../features/admin-panel/UpdateUserInfo";
import { CodeMobileGenerated } from "../features/authentication/common/CodeMobileGenerated";
import { CreateEvent } from "../features/events/CreateEvent";
import { EventProvider } from "../contexts/EventContext";
import { CreateEventSecondStep } from "../features/events/CreateEventSecondStep";
import { CreateEventThirdStep } from "../features/events/CreateEventThirdStep";
import { SignInPopupSuccess } from "../features/popups/SignInPopupSuccess";
import { AuthRoute } from "../features/authentication/common/AuthRoute";
import { Error404 } from "../features/error-pages/Error404";
import { EventDetails } from "../features/events/EventDetails";
import { EventUpdate } from "../features/events/EventUpdate";
import { CreateNewTicket } from "../features/events/CreateNewTicket";
import { EventTicketInfo } from "../features/events/EventTicketInfo";
import { EventTicketUpdate } from "../features/events/EventTicketUpdate";
import { BackendPopupError } from "../features/popups/BackendPopupError";
import { Attendee } from "../features/events/Attendee";
import { EventPage } from "../features/events/EventPage";
import { StripeConnect } from "../features/profile/StripeConnect";
import env from "../config/env";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(env.STRIPE_PK_KEY);
function App() {
  return (
    <Router>
      <AuthProvider>
        <EventProvider>
          <SignInPopupSuccess />
          <BackendPopupError />
          <Switch>
            {/** Authentication Routes */}
            {<AuthRoute exact path="/" type="guest" component={SignIn} />}
            <AuthRoute path="/sign-in" type="guest" component={SignIn} />
            <AuthRoute
              path="/code-mobile-generated"
              type="guest"
              component={CodeMobileGenerated}
            />
            {/** Events Routes */}
            <AuthRoute
              exact
              path="/events"
              type="private"
              component={HomeEvent}
            />
            <AuthRoute
              path="/create-event"
              type="private"
              component={CreateEvent}
            />
            <AuthRoute
              path="/create-event-second-step"
              type="private"
              component={CreateEventSecondStep}
            />
            <AuthRoute
              path="/create-event-third-step"
              type="private"
              component={CreateEventThirdStep}
            />
            <AuthRoute
              path="/events/details/:id"
              type="private"
              component={EventDetails}
            />
            <AuthRoute
              exact
              path="/:shortKey"
              type="share"
              component={EventPage}
            />
            <AuthRoute
              path="/events/update/:id"
              type="private"
              component={EventUpdate}
            />
            <AuthRoute
              path="/events/:id/ticket/create"
              type="private"
              component={CreateNewTicket}
            />
            <AuthRoute
              path="/events/attendee/:eventId/:phone"
              exact
              type="private"
              component={Attendee}
            />
            <AuthRoute
              path="/events/:id/tickets/info"
              type="private"
              component={EventTicketInfo}
            />
            <AuthRoute
              path="/events/:id/ticket/update"
              type="private"
              component={EventTicketUpdate}
            />
            {/** Profile Account Routes */}
            <AuthRoute
              path="/profile/contact-information"
              type="private"
              component={ContactInformation}
            />
            <AuthRoute
              path="/profile/admin-panel"
              type="private"
              component={AdminPanel}
            />
            <AuthRoute
              path="/profile/user-info/:userId"
              type="private"
              component={UserInfo}
            />
            <AuthRoute
              path="/profile/update-user-info/:userId"
              type="private"
              component={UpdateUserInfo}
            />
            <AuthRoute
              path="/profile/bank-account-information"
              type="private"
              component={BankAccountInformation}
            />
            <AuthRoute
              path="/profile/mobile-money-information"
              type="private"
              component={MobileMoneyInformation}
            />
            <Elements stripe={stripePromise}>
              <AuthRoute
                stripe={stripePromise}
                path="/profile/stripe-connect"
                type="private"
                component={StripeConnect}
              />
            </Elements>
            <Route component={Error404} />
          </Switch>
        </EventProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
