import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BadgeCheckIcon, XCircleIcon } from "@heroicons/react/solid";
import { CLOSE_POPUP_EVENT } from "../custom-events/constants";

import { off, on, trigger } from "../custom-events/customEvents";
import { defaultMessageErrorNotVerifiedUser } from "../../common/helper/codeErrorFirebaseAuth";
import env, { LANDING_URL } from "../../config/env";
import { logOut } from "../authentication/common/authHelper";

export function WrapperPopup({
  children,
  isSuccess = true,
  openEventName,
  isPopupSuccess = true,
  notAuth
}) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    trigger(CLOSE_POPUP_EVENT);
  }

  let changeButton = () => {
    if (notAuth) {
      return "Exit"
    } else {
      return "Close"
    }
  }

  let closeAndExitPopup = () => {
    if (notAuth) {
      logOut()
    } else {
      closeModal()
    }
  }



  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (openEventName) {
      on(openEventName, openModal);

      return () => {
        off(openEventName, openModal);
      };
    }
  });

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {}}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full text-white background max-w-md p-6 my-8 overflow-hidden text-center align-middle transition-all transform shadow-xl rounded-2xl">
                <div className="mt-2 flex flex-col justify-center items-center">
                  {isPopupSuccess && (
                    <p className="mb-4">
                      {isSuccess ? (
                        <BadgeCheckIcon className="text-violet h-24 w-24" />
                      ) : (
                        <img className="w-20 h-20" src={"/img/x.png"}/>
                      )}
                    </p>
                  )}

                  <div className="text-white">{children}</div>
                </div>

                <div className={`mt-4`}>
                  <button
                    type="button"
                    className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-violet border border-transparent rounded-md hover:bg-violet focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500`}
                    onClick={() => closeAndExitPopup()}
                  >
                    {changeButton()}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
