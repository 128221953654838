import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { Container } from "../../common/layouts/Container";
import { getUsers } from "../../API";
import { useFormik } from "formik";
import { trigger } from "../custom-events/customEvents";
import {
  OPEN_POPUP_DELETE_USER,
  OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS,
} from "../custom-events/constants";
import * as Yup from "yup";
import { DeleteUsersPopup } from "../popups/DeleteUsersPopup";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  fullName: Yup.string().required("Full name is required"),
  phone: Yup.string().required("Your phone number is required"),
  email: Yup.string().email("Invalid email").required("Your email is required"),
});

export function UserInfo() {
  const params = useParams();

  const [users, setUsers] = useState([]);
  const [, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const users = await getUsers();
      setUsers(users);
    };
    fetchData();
  }, []);

  const user = users.find((e) => e.id === Number(params.userId));

  const userStatus = () => {
    if (user?.accessLevel === "Active") {
      return (
        <p className="mx-5 my-2.5 text-sm font-bold text-violet">Access on</p>
      );
    } else if (user?.accessLevel === "Inactive") {
      return (
        <p className="mx-5 my-2.5 text-sm font-bold text-gray">Access off</p>
      );
    } else if (user?.accessLevel === "Verified") {
      return (
        <p className="mx-5 my-2.5 text-sm font-bold text-violet">Verified</p>
      );
    }
  };

  const formik = useFormik({
    initialValues: {
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {

        // firebaseAuth.currentUser.reload();
        trigger(OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS);
        trigger(OPEN_POPUP_DELETE_USER);
        // history.push(`/profile/admin-panel`)
      } catch (error) {
      }
      setIsLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleClickOnDeleteButton = () => {
    trigger(OPEN_POPUP_DELETE_USER);
  };

  return (
    <Container withDecoration={true}>
      <DeleteUsersPopup/>
      <div className="p-4">
        <div className="flex items-center">
          <Link to={`/profile/admin-panel`}>
            <img className="" src="/img/arrow_left.svg" alt="arrow" />
          </Link>
          <div className="flex-auto text-center text-base ext-white-700 p-6 font-bold">
            User Info
          </div>
        </div>

        <div className="mx-5 mt-5 flex justify-between">
          <p className="mt-1 cursor-pointer text-sm font-bold">
            {user?.displayName}
          </p>
          <div className="flex justify-between items-center">
            <Link
              className=""
              to={`/profile/update-user-info/${params.userId}`}
            >
              <button type="button" className="edit-button mr-2">
                <img src="/img/edit_button.svg" alt="edit icon" />
              </button>
            </Link>

            <form onSubmit={formik.handleSubmit}>
              <button type="submit"
                      className="edit-button mb-1.5 ml-2"
                      onClick={handleClickOnDeleteButton}
              >
                <img
                  src="/img/delete_button.svg"
                  alt="edit icon"
                />
              </button>
            </form>

          </div>
        </div>
        <p className="mx-5 mt-11 opacity-60 text-sm text-normal text-slate-300">
          User details
        </p>
        <p className="mx-5 my-2.5 text-sm text-normal">{user?.phone.replace(/[^\d]/g, '')}</p>
        <p className="mx-5 my-2.5 text-sm text-normal">{user?.email}</p>
        <p className="mx-5 my-2.5 text-sm text-normal">
          {user?.eventSize} people
        </p>
        <p className="mx-5 my-2.5 text-sm text-normal">
          {user?.eventCount} events per {user?.eventPeriod}
        </p>
        <p className="mx-5 my-2.5 text-sm text-normal">{user?.trashCountry}</p>
        <p className="mx-5 mt-11 opacity-60 text-sm text-normal text-slate-300">
          Status
        </p>
        {userStatus()}
        <p className="mx-5 mt-11 opacity-60 text-sm text-normal text-slate-300">
          Description
        </p>
        <p className="mx-5 my-2.5 text-sm text-normal">{user?.about}</p>
      </div>
    </Container>
  );
}
