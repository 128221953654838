import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import _ from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    responsive: false,
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        color: "transparent",
        borderColor: "transparent",
        tickColor: "transparent",
      },
    },
    y: {
      grid: {
        color: "transparent",
        borderColor: "transparent",
        tickColor: "transparent",
      },
    },
  },
};

const labels = ["S", "M", "T", "W", "T", "F", "S"];

export function TicketsDashboardWeek({ data }) {
  if (!data) {
    return null;
  }

  const generatedBarData = () => {
    const valuesGroups = _.groupBy(data.tickets, "ticketName");

    const groups = {};

    for (const [key, value] of Object.entries(valuesGroups)) {
      const values = new Array(7);
      values.fill(0);
      value.forEach((e) => {
        values[e.point] = e.ticketCount;
      });
      groups[key] = values;
    }

    let colors = [
      {
        borderColor: "rgba(167, 74, 253, 1)",
        backgroundColor: "rgba(167, 74, 253, 1)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.5)",
        backgroundColor: "rgba(167, 74, 253, 0.5)",
      },
      {
        borderColor: "rgba(167, 74, 253, 0.2)",
        backgroundColor: "rgba(167, 74, 253, 0.2)",
      },
    ];

    const datasets = [];

    const colorsLen = colors.length;

    let i = 0;
    for (const [key, value] of Object.entries(valuesGroups)) {

      datasets.push({
        label: key,
        fill: false,
        data: groups[key],
        borderColor: colors[i % colorsLen].borderColor,
        backgroundColor: colors[i % colorsLen].backgroundColor,
        tension: 0.4,
        pointStyle: "line",
      });
      i = i + 1;
    }

    return {
      labels,
      datasets,
    };
  };

  return <Line options={options} data={generatedBarData()} />;
}
