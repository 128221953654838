import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { updateMobileMoneyAccount } from "../../API";
import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";
import { OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS } from "../custom-events/constants";
import { trigger } from "../custom-events/customEvents";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  primaryProvider: Yup.string().required("Primary provider is required"),
  primaryNumber: Yup.string().required("Primary number is required"),
  secondaryProvider: Yup.string().required("Secondary provider is required"),
  secondaryNumber: Yup.string().required("Secondary number is required"),
});

export function MobileMoneyInformationForm({ data = {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      primaryProvider: data.primaryProvider || "",
      primaryNumber: data.primaryNumber || "",
      secondaryProvider: data.secondaryProvider || "",
      secondaryNumber: data.secondaryNumber || "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await updateMobileMoneyAccount({
          primaryProvider: values.primaryProvider,
          primaryNumber: values.primaryNumber,
          secondaryNumber: values.secondaryNumber,
          secondaryProvider: values.secondaryProvider,
        });

        trigger(OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <form className="mt-10" onSubmit={formik.handleSubmit}>
      <div className="mt-5">
        <label
          htmlFor="primaryProvider"
          className="block font-medium text-white-700"
        >
          Primary Provider
        </label>
        <select
          id="primaryProvider"
          name="primaryProvider"
          autoComplete="primaryProvider"
          className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.primaryProvider ? "red" : "gray"
          }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
          onChange={formik.handleChange}
          value={formik.values.primaryProvider}
        >
          <option value="">Selected provider</option>
          <option value="MTN">MTN</option>
          <option value="Vodafone">Vodafone</option>
          <option value="Orange">Orange</option>
        </select>
      </div>
      {formik.errors.primaryProvider ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.primaryProvider}
        </span>
      ) : null}

      <label className="block mt-5">
        <span className="text-white-700">Primary Number</span>
        <input
          id="primaryNumber"
          name="primaryNumber"
          type="phone"
          className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.primaryNumber ? "red" : "gray"
          }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
          placeholder="Example : +233 54 374 64 92"
          onChange={formik.handleChange}
          value={formik.values.primaryNumber}
        />
      </label>
      {formik.errors.primaryNumber ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.primaryNumber}
        </span>
      ) : null}

      <div className="mt-5">
        <label
          htmlFor="secondaryProvider"
          className="block font-medium text-white-700"
        >
          Secondary Provider
        </label>
        <select
          id="secondaryProvider"
          name="secondaryProvider"
          autoComplete="secondaryProvider"
          className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.secondaryProvider ? "red" : "gray"
          }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
          onChange={formik.handleChange}
          value={formik.values.secondaryProvider}
        >
          <option value="">Selected provider</option>
          <option value="MTN">MTN</option>
          <option value="Vodafone">Vodafone</option>
          <option value="Orange">Orange</option>
        </select>
      </div>
      {formik.errors.secondaryProvider ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.secondaryProvider}
        </span>
      ) : null}

      <label className="block mt-5">
        <span className="text-white-700">Secondary Number</span>
        <input
          id="secondaryNumber"
          name="secondaryNumber"
          type="phone"
          className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
              formik.errors.secondaryNumber ? "red" : "gray"
          }-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50`}
          placeholder="Example : +233 54 374 64 92"
          onChange={formik.handleChange}
          value={formik.values.secondaryNumber}
        />
      </label>
      {formik.errors.secondaryNumber ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.secondaryNumber}
        </span>
      ) : null}

      <button
        className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
        type="submit"
      >
        {isLoading ? <CircleSpinLoader size="6" bgColor="white" /> : null}{" "}
        <span className="ml-3">Update</span>
      </button>
    </form>
  );
}
