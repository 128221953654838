import React, { useEffect, useState } from "react";
import { useEventContext } from "../../contexts/EventContext";
import { useLocation, useParams } from "react-router-dom";

import {
  getEventsByShortKey,
  getEventsCodesForShare,
  getEventWithTypeTickets,
} from "../../API";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { LANDING_URL } from "../../config/env";

export function EventPage() {
  const [data, setData] = useState([]);
  const location = useLocation();
  const [tgUrl, setTgUrl] = useState("");
  const [waUrl, setWaUrl] = useState("");
  const [typeTickets, setTypeTickets] = useState([]);
  const [readMore, setReadMore] = useState(false);
  const [locale, setLocale] = useState(false);
  const maxDescription = data?.description?.substring(0, 9999);
  const minDescription = data?.description?.substring(0, 200);
  const readMoreRemove = data?.description?.length;

  const shortKey = location.pathname.replaceAll("/", "");

  useEffect(() => {
    getEventsByShortKey(shortKey).then((e) => {
      setData(e);
    });
  }, [shortKey]);

  const id = data.id;

  useEffect(() => {
    const fetchData = async () => {
      getEventsCodesForShare(id).then((e) => {
        setTgUrl(e.tgLink);
        setWaUrl(e.waLink);
      });
      getEventWithTypeTickets(id).then((e) => {
        setTypeTickets(e.result?.typeTickets);
      });
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const newGPS = data?.gps?.split(", ");

  const center = {
    lat: 0,
    lng: 0,
  };

  if (newGPS) {
    center.lat = Number(newGPS[0]);
    center.lng = Number(newGPS[1]);
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyABZM7PcqcNkR7fH-8smhfHyL2rq-2vLJs",
  });

  const [map, setMap] = React.useState(null);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: newGPS,
      map,
      title: "Hello World!",
    });
  };

  const renderReadMore = () => {
    if (readMoreRemove < 198) {
      return null;
    } else {
      return (
        <div
          style={{ textAlign: "right" }}
          onClick={() => setReadMore(!readMore)}
          className={`font-roboto mt-5 text-violet cursor-pointer background`}
        >
          Read more
        </div>
      );
    }
  };

  return (
    <div>
      <div className="background__event-page"></div>
      <div className="body__event-page ">
        <div className="padding-0 pt-3.5 pb-3.5 padding-container">
          <div className="text-left desktop-header-container max-w-lg flex items-center justify-between bg-black rounded-lg border-violet">
            <img
              className="desktop-logo"
              src="/img/logo__event-page.svg"
              alt=""
            />
            <div className=" desktop-header-font text-lg font-poppins font-bold">
              {data?.name}
            </div>
            <div
              className="share-logo cursor-pointer"
              style={{ width: "65px" }}
            >
              {/*<img className="mx-1" src="/img/share_ep.svg" alt="" />*/}
              {/*<div className="text-violet">share</div>*/}
            </div>
          </div>
        </div>
        <div className="desktop-flex text-xs">
          <div className="d-p-l-40 desktop-body">
            <div className="padding-container">
              <div className=" desktop-margin date__event-page flex items-center">
                <img src="/img/Shape.svg" alt="" />
                <div className="flex ml-2 align-middle justify-center">
                  <div>
                    <div className="text-lg font-bold">{data?.startDate},</div>
                    <div>{data?.startTime}</div>
                  </div>
                  <div className="mr-4 ml-4 flex flex-col align-middle justify-center">-</div>
                  <div>
                    <div className="text-lg font-bold">{data?.endDate},</div>
                    <div>{data?.endTime}</div>
                  </div>
                </div>
              </div>
              <div className="flex mt-6 text-lg font-bold">
                <img className="mr-4" src="/img/Path.svg" alt="" />
                <div>
                  {typeTickets.map((e) => {
                    if (e.price === "0.00") {
                      return "Free entrance";
                    }
                    if (e.currency === "---") {
                      return "";
                    }
                    return (
                      <div className="">
                        <div className="flex font-bold">
                          {e.price}
                          <div className="ml-2 mr-4">{e.currency}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className=" location__event-page flex mt-6">
                <img src="/img/Loc.svg" alt="" />
                <div className="ml-3.5 text-xs font-bold">{data?.address}</div>
              </div>
            </div>
            <div
              style={{ textAlign: "right" }}
              onClick={() => setLocale(!locale)}
              className="display-none my-4 font-roboto text-violet cursor-pointer background padding-container"
            >
              Locate on the map
            </div>
            <div className={`google-map ${locale ? "" : "locale"}`}>
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "250px",
                  }}
                  defaultZoom={20}
                  defaultCenter={{ lat: 18.559008, lng: -68.388881 }}
                  options={{
                    mapTypeControl: false,
                    streetViewControl: false,
                  }}
                  center={center}
                  zoom={19}
                  onUnmount={onUnmount}
                  onGoogleApiLoaded={({ map, maps }) =>
                    renderMarkers(map, maps)
                  }
                >
                  <Marker position={center} />
                </GoogleMap>
              )}
            </div>
          </div>
          <div className="desktop-body padding-container mt-3">
            <div className=" text-xl font-bold">Buy tickets on</div>
            <div className="justify-between w-full mx-auto mt-6 flex">
              <div
                style={{ width: 140 }}
                className=" cursor-pointer bg-black border-violet rounded-lg h-20"
              >
                <a href={tgUrl} className="mt-2.5 mx-auto w-20">
                  <img
                    className="mt-2.5 mx-auto w-10"
                    src="/img/tg.svg"
                    alt=""
                  />
                  <div className="mt-1 text-xs text-violet text-center">
                    Telegram
                  </div>
                </a>
              </div>
              {/*<div*/}
              {/*  style={{ width: 140 }}*/}
              {/*  className="cursor-pointer bg-black border-violet rounded-lg h-20"*/}
              {/*>*/}
              {/*  <a href={waUrl} className="mt-2.5 mx-auto w-20">*/}
              {/*    <img*/}
              {/*      className="mt-2.5 mx-auto w-10"*/}
              {/*      src="/img/ws.svg"*/}
              {/*      alt=""*/}
              {/*    />*/}
              {/*    <div className="mt-1 text-xs text-violet text-center">*/}
              {/*      WhatsApp*/}
              {/*    </div>*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
            <div className=" mt-7 text-xl font-bold">By the way</div>
            <div className="box mt-4 text-xs w-80">
              {readMore ? maxDescription : minDescription}
              <div className={`-4 read-more`}>
                {renderReadMore()}

                <div className=" text-xs w-full mx-auto cursor-pointer my-7 py-1.5 bg-black text-violet border-violet rounded-lg text-center ">
                  <a href="https://yozhevika.online/">yozhevika.online</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
