import React, { useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";

import { Container } from "../../common/layouts/Container";
import { Title } from "../../common/titles/Title";
import { NavbarConnected } from "../../common/navbar/NavbarConnected";
import { ListEvent } from "./ListEvent";
import { EmptyEvent } from "./EmptyEvent";
import { OPEN_POPUP_EVENT_GET_ACCOUNT_DATA_ERROR } from "../custom-events/constants";
import { trigger } from "../custom-events/customEvents";
import { GroupFormLoader } from "../../common/loaders/GroupFormLoader";
import { GetAccountDataPopupError } from "../popups/GetAccountDataPopupError";
import { useEventContext } from "../../contexts/EventContext";
import { getEvents } from "../../API";

export function HomeEvent() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { resetLocalEventData } = useEventContext();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const result = await getEvents();
        setIsLoading(false);
        setData(result);
      } catch (error) {
        trigger(OPEN_POPUP_EVENT_GET_ACCOUNT_DATA_ERROR);
      }
    }

    fetchData();
  }, []);

  const handleClickOnCreateButton = () => {
    resetLocalEventData();
    history.push("/create-event");
  };

  return (
    <Container>
      <GetAccountDataPopupError />
      <NavbarConnected />
      <div className="pb-2.5 font-poppins px-5 text-white text-3xl font-bold">Events</div>
      {isLoading ? (
        <GroupFormLoader />
      ) : data && data.length > 0 ? (
        <ListEvent data={data} />
      ) : (
        <EmptyEvent />
      )}
      <div className="fixed bottom-5 right-5 button-bottom-bg">
        <div
          onClick={handleClickOnCreateButton}
          className="absolute bottom-0 right-0"
        >
          <PlusCircleIcon className="h-14 w-14 text-violet" />
        </div>
      </div>
    </Container>
  );
}
