import React from "react";
import { FormLoader } from "./FormLoader";

export function GroupFormLoader() {
  return (
    <div className="mt-5 px-6">
      <FormLoader />
      <FormLoader />
    </div>
  );
}
