import React, { useEffect, useState } from "react";
import { TicketsDashboardWeek } from "./TicketsDashboardWeek";
import { TicketsDashboardMonth } from "./TicketsDashboardMonth";
import { TicketsDashboardYear } from "./TicketsDashboardYear";
import { getDashboardTickets } from "../../../API";

export function Tickets({ eventId }) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [tickets, setTickets] = useState([]);

  const [period, setPeriod] = useState("week");
  const [data, setData] = useState(null);

  const fetchdata = async (method) => {
    setIsLoading(true);
    try {
      const result = await method();
      setTickets(result);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchdata(() => {
      getDashboardTickets(eventId, period).then((data) => setData(data));
    });
  }, [eventId, period]);

  const renderTabContent = () => {
    if (period === "week") {
      return (
        <div>
          <TicketsDashboardWeek data={data} />
        </div>
      );
    }
    if (period === "month") {
      return (
        <div>
          <TicketsDashboardMonth data={data} />
        </div>
      );
    }
    if (period === "year") {
      return (
        <div>
          <TicketsDashboardYear data={data} />
        </div>
      );
    }
  };

  return (
    <div>
      <nav className="flex justify-between mt-5 my-5">
        <div className="font-semibold">Tickets</div>

        <ul className="flex justify-between  ">
          <div className="font-normal text-gray-400">current:</div>
          <div>
            <div className="flex justify-between">
              <button
                className={
                  period === "week"
                    ? "ml-4 font-bold text-violet"
                    : "ml-4 font-bold text-gray-400"
                }
                onClick={() => setPeriod("week")}
              >
                Week
              </button>
              <button
                className={
                  period === "month"
                    ? "ml-4 font-bold text-violet"
                    : "ml-4 font-bold text-gray-400"
                }
                onClick={() => setPeriod("month")}
              >
                Month
              </button>
              <button
                className={
                  period === "year"
                    ? "ml-4 font-bold text-violet"
                    : "ml-4 font-bold text-gray-400"
                }
                onClick={() => setPeriod("year")}
              >
                Year
              </button>
            </div>
          </div>
        </ul>
      </nav>

      <div className="w-full">
        {renderTabContent()}
      </div>
    </div>
  );
}

export default Tickets;
