import React, { useState } from "react";
import PropTypes from "prop-types";

export function Tabs({ tabs = [], activeTab = 0 }) {
  const [openTab, setOpenTab] = useState(activeTab);

  React.useEffect(() => {
    setOpenTab(activeTab);
  }, [activeTab]);

  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        <ul className="flex mb-0 list-none pt-3 pb-4 flex-row" role="tablist">
          {tabs.map((tab, index) => {
            return (
              <li
                key={`${tab.text}-${index}`}
                className={`${openTab === index ? 'tabs_text_active' : 'tabs_text'} -mb-px last:mr-0 flex-auto text-center`}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(index);
                  }}
                  data-toggle="tab"
                  href={`#link${index}`}
                  role="tablist"
                >
                  <i className=" fas fa-space-shuttle text-base mr-1" />
                  {tab.text}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
          <div className="px-4 py-5 flex-auto">
            <div className="tab-content tab-space">
              {tabs.map(({ component: TabComponent, data }, index) => {
                return (
                  <div
                    key={`tab-component-render-${index}`}
                    className={openTab === index ? "block" : "hidden"}
                    id={`link${index}`}
                  >
                    <TabComponent data={data} setOpenTab={setOpenTab} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired,
    })
  ).isRequired,
};
