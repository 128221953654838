import firebase, { firebaseAuth } from "../../../app/firebaseConfig";

export function recaptchaVerifierVisible(recaptchaId) {
  return new firebase.auth.RecaptchaVerifier(recaptchaId, {
    size: "invisible",
  });
}

export async function phoneSignIn({ phoneNumber, recaptcha }) {
  try {
    const confirmationResult = await firebaseAuth.signInWithPhoneNumber(
      phoneNumber,
      recaptcha
    );

    return confirmationResult;
  } catch (error) {
    console.error("phoneSignIn", { error });

    throw error;
  }
}

export async function verifyCode({ confirmationResult, code }) {
  try {
    const result = await confirmationResult.confirm(code);
    const user = result.user;

    return user;
  } catch (error) {
    console.error("verifyCode", { error });

    throw error;
  }
}

export const logIn = (token) => {
  localStorage.setItem("token", token);
  window.location.reload();
};

export const logOut = () => {
  localStorage.clear();
  window.location.reload(true);
};
