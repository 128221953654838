import React from "react";
import { useHistory, useParams } from "react-router-dom";

import ListTickets from "./ListTickets";
import { Title } from "../../common/titles/Title";
import { ListScanners } from "./ListScanners";

export function TypeTicketsDetails({ data = {} }) {
  const history = useHistory();
  const { id } = useParams();

  const { event } = data;
  const handleClickOnPlusButton = () => {
    history.push(`/events/${id}/ticket/create`);
  };

  return (
    <div>
      <Title extraStyles="pl-0 pr-0 pb-3 flex justify-between items-center">
        <div className="text-white">Tickets</div>
        <button
          onClick={handleClickOnPlusButton}
          className="bg-violet rounded flex justify-between items-center text-white text-xs px-4 py-2"
        >
          <img src="/img/plus.svg" alt="plus" className="mr-3" width="10" /> ADD
        </button>
      </Title>
      <div className="text-sm font-medium">{`Sold tickets ${
        event?.soldTickets
      } / ${event?.totalTickets || "-"}`}</div>
      <ListTickets typeTickets={data.typeTickets} />
      <ListScanners eventId={id} />
    </div>
  );
}
