import React, { useState } from "react";
import { useFormikContext, Formik, Form, Field } from "formik";
import { useLocation } from "react-router-dom";

import { Container } from "../../../common/layouts/Container";
import { Navbar } from "../../../common/navbar/Navbar";
import { Title } from "../../../common/titles/Title";
import { logIn } from "./authHelper";
import { trigger } from "../../custom-events/customEvents";
import { OPEN_POPUP_AUTH_ERROR } from "../../custom-events/constants";
import { useAuth } from "../../../contexts/AuthContext";
import { defaultMessageError } from "../../../common/helper/codeErrorFirebaseAuth";
import { CircleSpinLoader } from "../../../common/loaders/CircleSpinLoader";
import { checkPhone, signIn } from "../../../API";
import { AuthenticationPopupError } from "../../popups/AuthenticationPopupError";

const AutoSubmitCode = () => {
  // Grab values and submitForm from context
  const { values, submitForm } = useFormikContext();
  React.useEffect(() => {
    // Submit the form imperatively as an effect as soon as form values.code are 6 digits long
    const id = document.querySelector('.btnId')
    if (values.code.length > 0) {
      id.classList.add('opacity_1')
    } else {
      id.classList.remove('opacity_1')
    }
    if (values.code.length === 6) {
      submitForm();
    }
  }, [values, submitForm]);
  return null;
};

export function CodeMobileGenerated() {
  const location = useLocation();
  const { setErrorAuthMsg, errorAuthMsg } = useAuth();
  let { phone } = location;
  const [isLoading, setIsLoading] = useState(false);

  const handleResendSMS = async () => {
    setIsLoading(true);

    try {
      await checkPhone(phone);
    } catch (error) {
      trigger(OPEN_POPUP_AUTH_ERROR);
    }
    setIsLoading(false);
    window?.recaptchaVerifier?.clear();
  };

  return (
    <Container>
      <AuthenticationPopupError message={errorAuthMsg} />
      <Navbar />
      <div className="text-center p-6">
        <Title size="text-2xl" text="Confirm mobile number" />
        <p className="text-white">
          We’ve sent a code by SMS to phone number{" "}
          <span className="font-bold text-lg">{phone}</span>. Wrong number?
        </p>
        <p className="mt-8 text-white">Your 6-digit code</p>
        <Formik
          initialValues={{ code: "" }}
          validate={(values) => {
            const errors = {};
            if (values.code.length < 5) {
              errors.code = "Invalid code. Too short.";
            }
            return errors;
          }}
          onSubmit={async (values, actions) => {
            if (values.code === null) return;
            try {
              const auth = await signIn(phone, values.code);
              if (!auth.success) {
                setErrorAuthMsg(defaultMessageError);
                trigger(OPEN_POPUP_AUTH_ERROR);
                return null;
              }
              logIn(auth.token);
            } catch (error) {
              // trigger(OPEN_POPUP_AUTH_ERROR);
              // console.log("CodeMobileGenerated", { error });
            }
            actions.setSubmitting(false);
          }}
        >
          <Form className="mt-8">
            <Field
              id="input"
              name="code"
              type="tel"
              className="w-full text-white rounded-xl border-violet text-center p-5 text-2xl tracking-widest background-transparent"
              maxLength="6"
            />
            <AutoSubmitCode />
          </Form>
        </Formik>
        <div className="mt-5">
          <div id="recaptcha-container-resend" className="mt-4" />
          <button
            className={`btnId flex justify-center mt-5 text-white w-full rounded p-2`}
            type="button"
            onClick={() => handleResendSMS()}
          >
            {isLoading ? <CircleSpinLoader size="6" bgColor="white" /> : null}{" "}
            <span className="ml-3">Resend</span>
          </button>
        </div>
      </div>
    </Container>
  );
}
