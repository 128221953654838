import { useFormik } from "formik";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Map from "../map/Map";

import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";
import { OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS } from "../custom-events/constants";
import { trigger } from "../custom-events/customEvents";
import { updateEvent } from "../../API";
import { timezoneList } from "../../constans";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  venueName: Yup.string().required("The vanue name of event is required"),
  address: Yup.string().required("The adress is required"),
  gps: Yup.string().required("The adress is required"),
  city: Yup.string().required("The city is required"),
  country: Yup.string().required("The country is required"),
  timezone: Yup.string().required("Timezone is required"),
});

const renderTimezones = () => {
  return timezoneList.map((i) => (
    <option value={i} key={i}>
      {i}
    </option>
  ));
};

export function EventUpdateVenue({ data = {} }) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      venueName: data.venueName || "",
      address: data.address || "",
      city: data.city || "",
      country: data.country || "",
      gps: data.gps || "",
      timezone: data.timezone || "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await updateEvent(id, {
          eventData: {
            ...values,
          },
        });

        trigger(OPEN_POPUP_EVENT_UPDATE_ACCOUNT_SUCCESS);
      } catch (error) {
        console.log("EventUpdateVenue", { error });
      }
      setIsLoading(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <form className="mt-10" onSubmit={formik.handleSubmit}>
      <label className="block my-3">
        <span className="text-white-700">Venue name</span>
        <input
          id="venueName"
          name="venueName"
          type="text"
          className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.venueName ? "red" : "gray"
          }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
          onChange={formik.handleChange}
          value={formik.values.venueName}
        />
      </label>
      {formik.errors.venueName ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.venueName}
        </span>
      ) : null}

      <div className="my-4">
        <Map
          errors={formik.errors}
          address={formik.values.address}
          gps={formik.values.gps}
          onChangeAddress={(value) => formik.setFieldValue("address", value)}
          onChangeGPS={(value) => formik.setFieldValue("gps", value)}
          onChangeCity={(value) => formik.setFieldValue("city", value)}
          onChangeCountry={(value) => formik.setFieldValue("country", value)}
          onChangeTZ={(value) => formik.setFieldValue("timezone", value)}
        />
      </div>
      <label className="block my-3">
        <span className="text-white-700">City</span>
        <input
          id="city"
          name="city"
          type="text"
          className={`mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.city ? "red" : "gray"
          }-300 shadow-sm focus:border-violet border-violet background focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
          onChange={formik.handleChange}
          value={formik.values.city}
        />
      </label>
      {formik.errors.city ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.city}
        </span>
      ) : null}

      <label className="block my-3">
        <span className="text-white-700">Country</span>
        <input
          id="country"
          name="country"
          type="text"
          className={`mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.country ? "red" : "gray"
          }-300 shadow-sm ffocus:border-violet border-violet background focus:ring focus:ring-purple-400 focus:ring focus:ring-opacity-50`}
          onChange={formik.handleChange}
          value={formik.values.country}
        />
      </label>
      {formik.errors.country ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {formik.errors.country}
        </span>
      ) : null}

      <div className="mt-5">
        <label htmlFor="category" className="block font-medium text-white-700">
          Timezone
        </label>
        <select
          id="timezone"
          name="timezone"
          autoComplete="category"
          className={`mt-1 p-4 border block w-full rounded-md border-${
            formik.errors.timezone ? "red" : "gray"
          }-300 shadow-sm focus:border-violet border-violet background focus:ring focus:ring-purple-400 focus:ring focus:ring-opacity-50`}
          onChange={formik.handleChange}
          value={formik.values.timezone}
        >
          {renderTimezones()}
        </select>
        {formik.errors.timezone ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {formik.errors.timezone}
          </span>
        ) : null}
      </div>

      <button
        className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
        type="submit"
      >
        {isLoading ? <CircleSpinLoader size="6" bgColor="white" /> : null}{" "}
        <span className="ml-3">Update Event</span>
      </button>
    </form>
  );
}
