import React, { useContext, useEffect, useState } from "react";
import { phoneSignIn } from "../features/authentication/common/authHelper";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuth, setIsAuth] = useState(localStorage.getItem("token") || false);
  const [errorAuthMsg, setErrorAuthMsg] = useState("");
  const [authNumber, setAuthNumber] = useState("");

  function signIn({ phoneNumber, recaptcha }) {
    return phoneSignIn({ phoneNumber, recaptcha });
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      return setIsAuth(true);
    }
    setIsAuth(false);
  }, []);

  const value = {
    signIn,
    isAuth,
    errorAuthMsg,
    setErrorAuthMsg,
    authNumber,
    setAuthNumber,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
