import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Container } from "../../common/layouts/Container";
import { Title } from "../../common/titles/Title";
import { Link, useHistory } from "react-router-dom";
import { StepsCreationEvent } from "./StepsCreationEvent";
import { useEventContext } from "../../contexts/EventContext";
import { CircleSpinLoader } from "../../common/loaders/CircleSpinLoader";
import Map from "../map/Map";
import { timezoneList } from "../../constans";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  venueName: Yup.string().required("The venue name of event is required"),
  address: Yup.string().required("The address is required"),
  gps: Yup.string().required("The gps is required"),
  city: Yup.string().required("The city is required"),
  country: Yup.string().required("The country is required"),
  timezone: Yup.string().required("Timezone is required"),
});

export function CreateEventSecondStep() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { eventData, saveLocalEventData, countries } = useEventContext();
  const formik = useFormik({
    initialValues: {
      venueName: eventData.venueName || "",
      address: eventData.address || "",
      city: eventData.city || "",
      country: eventData.country || "",
      gps: eventData.gps || "",
      timezone: eventData.timezone || "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      saveLocalEventData(values);

      setIsLoading(false);
      history.push("/create-event-third-step");
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const renderTimezones = () => {
    return timezoneList.map((i) => (
      <option value={i} key={i}>
        {i}
      </option>
    ));
  };

  const renderCountries = () => {
    return countries?.map((i) => (
      <option value={i.country} key={i.code}>
        {i.country}
      </option>
    ));
  };

  return (
    <Container className="bg-white">
      <div className="p-4">
        <div className="flex items-center">
          <Link
            to="/create-event"
            onClick={() => saveLocalEventData(formik.values)}
          >
            <img className="" src="/img/arrow_left.svg" alt="arrow" />
          </Link>
          <div className="flex-auto text-center">
            <Title text="Create Event" />
          </div>
        </div>

        <StepsCreationEvent isFirstStepFinished={true} step={2} />

        <form className="mt-10" onSubmit={formik.handleSubmit}>
          <label className="block my-3">
            <span className="text-white-700">Venue name</span>
            <input
              id="venueName"
              name="venueName"
              type="text"
              className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
                formik.errors.venueName ? "red" : "gray"
              }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.venueName}
            />
          </label>
          {formik.errors.venueName ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.venueName}
            </span>
          ) : null}

          <Map
            errors={formik.errors}
            address={formik.values.address}
            gps={formik.values.gps}
            onChangeAddress={(value) => formik.setFieldValue("address", value)}
            onChangeGPS={(value) => formik.setFieldValue("gps", value)}
            onChangeCity={(value) => formik.setFieldValue("city", value)}
            onChangeCountry={(value) => formik.setFieldValue("country", value)}
            onChangeTZ={(value) => formik.setFieldValue("timezone", value)}
          />
          <label className="block my-3">
            <span className="text-white-700">City</span>
            <input
              id="city"
              name="city"
              type="text"
              className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
                formik.errors.city ? "red" : "gray"
              }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.city}
            />
          </label>
          {formik.errors.city ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.city}
            </span>
          ) : null}

          <div className="block my-3">
            <label
              htmlFor="country"
              className="block font-medium text-white-700"
            >
              Country
            </label>
            <select id="country" 
              name="country"
              autoComplete="country"
              className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
                formik.errors.country ? "red" : "gray"
              }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.country}> 
              <option value="">Choose country</option>            
              {renderCountries()}
            </select>
          </div>
          {formik.errors.country ? (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {formik.errors.country}
            </span>
          ) : null}

          <div className="mt-5">
            <label
              htmlFor="timezone"
              className="block font-medium text-white-700"
            >
              Timezone
            </label>
            <select
              id="timezone"
              name="timezone"
              autoComplete="category"
              className={`background border-violet mt-1 p-4 border block w-full rounded-md border-${
                formik.errors.timezone ? "red" : "gray"
              }-300 shadow-sm focus:border-violet focus:ring focus:ring-purple-400 focus:ring-opacity-50`}
              onChange={formik.handleChange}
              value={formik.values.timezone}
            >
              {renderTimezones()}
            </select>
            {formik.errors.timezone ? (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {formik.errors.timezone}
              </span>
            ) : null}
          </div>
          <button
            className="flex justify-center mt-5 bg-violet hover:bg-violet text-white w-full rounded p-2"
            type="submit"
          >
            {isLoading ? <CircleSpinLoader size="6" bgColor="white" /> : null}{" "}
            <span className="ml-3">Next</span>
          </button>
        </form>
      </div>
    </Container>
  );
}
